<template>
    <div class="py-4 container-fluid">

      <Cargador v-if="loading"/>
      <div v-if="!loading" class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.stop.prevent="createTicket()">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Título del Asunto *</label>
                      <input type="text" required v-model="form.title" class="form-control">
                    </div>
                    <div class="form-group">
                        <label>Descripción *</label>
                        <textarea required v-model="form.description" class="form-control" rows="5"></textarea>
                    </div>
                    <div class="form-group">
                        <label>Notas privadas </label>
                        <textarea v-model="form.private_notes" class="form-control" rows="5"></textarea>
                    </div>
                    


                    <div class="form-group">
                      <label for="">Fecha de Vencimiento</label>
                      <input  v-model="form.expiration_on" type="date" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-6">
                    <searchUsers :to_id="form.to_id" :requiredClient="false" :requiredAsesores="false"  v-on:update:to_id="form.to_id = $event" v-on:update:users_ids="form.users_ids = $event" :title="'Usuarios del Asunto'" :users_ids="form.users_ids"/>
                  </div>
                  <div class="col-md-12 ">
                    <button v-if="!updating" type="submit" class="btn btn-primary" name="button">
                      Crear Asunto
                    </button>
                    <Cargador v-if="updating"/>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div v-if="!loading && error" class="alert">
            <div class="alert alert-danger">
              Parece que hay algún error
            </div>
          </div>
        </div>
      </div>


    </div>
</template>

<script>

import Cargador from "@/components/Cargador.vue";
import searchUsers from "@/components/SearchUsers.vue";




export default {
  name: "CreateTicketView",
  data() {
    return {
      loading:false,
      updating: false,
      error:false,
      form: {
        title:null,
        description:null,
        users_ids:[],
        to_id:null,
        private_notes:null,
        chat_id:null
      }


    }
  },
  props: {
    title:null,
    description:null,
    users_ids:[],
    to_id: null,
    private_notes:null,
    chat_id:null,
    name_to: null
  },
  components: {
    Cargador,
    searchUsers

  },
  created(){
    // this.getData()
    this.form.to_id = this.to_id
    this.form.chat_id = this.chat_id
  },
  methods: {
    isAdmin(pivot) {
      if(pivot.admin)
        return "admin"
      return false;
    },
    createTicket() {
      
      this.updating =true
      
      this.$axios.post(this.$store.state.baseUrl+'/api/ticket/create', this.form)
        .then(response => {
          if(response.data.rc == 1) {
            this.$router.push({path: '/tickets/'+response.data.data.id })
            
          } else {
            this.error = true
          }
        })
        .catch(() =>  {
            this.error = true
        })
        .finally(() =>  {
            this.updating = false
        })
    },
    getData() {
      
      
      this.$axios.get(this.$store.state.baseUrl+'/api/tickets/'+this.$route.params.id, null)
        .then(response => {
          if(response.data.rc == 1) {
            this.ticket = response.data.data
            this.loading = false
          } else {
            this.error = true
          }
        })
        .catch(() =>  {
            this.error = true
        })
        .finally(() =>  {
            this.loading = false
        })
    },
    requestMeet(){
      if(this.ticket.meet !== null) {
        if(!confirm('Ya tienes una reunión asignada. ¿estás seguro de solcitar otra?'))
          return true
      }
      this.$router.push({path: '/meets/request' })
    },
    changeStatus(status) {
      this.updating = true;
      this.ticket.status = status;
      this.update();
    },
    update() {
      this.updating = true
      
      
      var self = this
      this.$axios.post(this.$store.state.baseUrl+'/api/tickets/'+this.$route.params.id+'/update', this.ticket)
        .then(response => {
          if(response.data.rc == 1) {
            self.ticket = response.data.data
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(
          this.updating = false
        )
    }
  }

};
</script>
