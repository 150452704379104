<template>
  <form @submit.stop.prevent="save()" class="row">
    
      <div class="col-md-7">
            <i v-if="field.answer !== null && !error" class="fa fa-circle me-1 m-1 success"></i>
            <i v-if="field.answer === null && !error" class="fa fa-circle me-1 m-1 pending"></i>
            <i v-if="error" class="fa fa-circle me-1 m-1 warning"></i>

          <label >
            {{field.title}}
            <small v-if="field.type=='text'">(Texto)</small>
            <small v-if="field.type=='document'">(Documento)</small>
            <small v-if="field.type=='date'">(Fecha) dd/mm/yyyy</small>
          </label>




        <!-- Text -->
        <div v-if="field.type == 'text'">
          <input required v-model="answer.answer"  type="text" class="form-control">
        </div>

        <!-- Document -->
        <div v-if="field.type == 'document'" class="row">
          <div class="col-md-8">
            <input required ref="fileInput" @change="handleFileUpload( $event )"  type="file" class="form-control">
          </div>
          <div class="col-md-4">
            <input required v-if="field.answer !== null && !error" type="text" class="form-control" :value="field.title" readonly placeholder="Nombre documento (opcional)">
          </div>
          <div v-if="field.answer !== null && field.answer.document !== null" class="col-md-12">
            <router-link
              class="dropdown-item"
              target="_blank"
              :to="{
                name: 'Archivo',
                params: {
                  id:field.answer.document.id
                }

              }"
              >
              <button  class="btn btn-link btn-left" type="button" name="button">
                Ver Documento
              </button>
            </router-link>         </div>
          <div class="flex">

          </div>


          </div>

        <!-- Date  -->
        <div v-if="field.type =='date'" class="">
          <input v-model="answer.answer" type="date" class="form-control">
        </div>
      </div>
      <div  class="col-md-5 text-center">
        <Cargador v-if="loading"/>
        <div class="valdationASaveButton" >
          <button v-if="!loading"  class="btn btn-info m-auto" type="submit" name="button">
            Guardar
          </button>
          <button v-if="answer.status === 'pending' && this.$store.state.auth.rol == 'asesor'" @click="validate(answer)" class="btn btn-link m-auto" type="button" name="button">
            Validar
          </button>
          <div v-if="answer.status === 'confirmed'" class="">
            Validado ✅
          </div>
          <button v-if="answer.status === 'pending' && this.$store.state.auth.rol == 'asesor'" @click="deny(answer)" class="btn btn-link m-auto denyButton" type="button" name="button">
            Denegar
          </button>

        </div>

      </div>
      <div v-if="answer.status === 'confirmed'" >
        <div class="">
          Validado ✅
        </div>
      </div>

   
  </form>
</template>


<script>
import Cargador from "@/components/Cargador.vue";


export default {
  data() {
    return {
      answer: {

      },
      file:null,
      loading: false,
      error: false,
      field: {
        answer: {

        }
      }

    }
  },
  props: ['fieldProp','requestForm_id'],
  mounted() {
    this.field = this.fieldProp
    this.passData();
  },
  components: {
    Cargador
  },
  methods: {
    passData() {
      if(this.field.answer !== null)
        this.answer = this.field.answer
    },
    handleFileUpload( event ){
      this.file = event.target.files[0];
    },
    save() {
      this.loading = true;

      let formData = new FormData();
      if(this.field.type == 'text')
        formData.append('answer', this.answer.answer);
      if(this.field.type == 'date')
        formData.append('answer', this.answer.answer);
      if(this.field.type == 'document' && this.file !== null)
        formData.append('file', this.file);



      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/request_form/'+this.requestForm_id+'/answer/'+this.field.id, formData)
        .then(response => {
          if(response.data.rc == 1) {
            this.answer = response.data.data
            this.field.answer = response.data.data
            this.file = null;
          } else {
            this.error = true
          }
        })
        .catch(() =>  {
            this.error = true
        })
        .finally(() =>  {
            this.loading = false
        })
    },
    validate(answer){
      this.loading = true;
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/answer/'+answer.id+'/validate',null)
        .then(response => {
          if(response.data.rc == 1) {
            this.field.answer.status = 'confirmed';
          } else {
            this.error = true
          }
        })
        .catch(() =>  {
            this.error = true
        })
        .finally(() =>  {
            this.loading = false
        })
    },
    deny(answer){
      this.loading = true;
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/answer/'+answer.id+'/deny',null)
        .then(response => {
          if(response.data.rc == 1) {
            this.field.answer = {}
            window.location.reload();
          } else {
            this.error = true
          }
        })
       
    }



















  }
}
</script>
