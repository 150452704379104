<template>
  <sidenav
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
    v-if="this.$store.state.showSidenav"
  />
  <Cargador v-if="loading" />
  <div v-if="this.$store.state.notificationsPush.length >=1" class="contieneNotisPush">
    <pushNoti  v-for="(notification,index) in this.$store.state.notificationsPush"  :key="notification.id" :index="index" :notification="notification"  />
  </div>

      <main v-if="!loading"
        class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
        :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
      >

    <PermisionAlert v-if="this.showAlertNotifications"/>
  
    <!-- nav -->
    <navbar
      :class="[navClasses]"
      :minNav="navbarMinimize"
      :companiesIn="this.$store.state.general.companies"
      v-if="this.$store.state.showNavbar"
      @reload="reloadMain"
    />
    <Error v-if="this.$store.state.errores.length > 0"/>
      
    
    <router-view class="vista" v-if="!this.loading" :key="this.key" />
    <SocketInfo v-if="allowConectionSocket"/>
    <app-footer v-show="this.$store.state.showFooter" />
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Cargador from "@/components/Cargador.vue";
import pushNoti from "@/components/pushNoti.vue";
import Error from "@/views/components/Error.vue";
import PermisionAlert from "@/components/general/alertPermision.vue";
import SocketInfo from "@/components/socket/conection.vue";


// import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
import cookie from 'cookiejs';



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";




// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCW7RnUid3HLivLFVPAlYK3ua7-qG4g03w",
  authDomain: "innoe-test.firebaseapp.com",
  projectId: "innoe-test",
  storageBucket: "innoe-test.appspot.com",
  messagingSenderId: "795867377926",
  appId: "1:795867377926:web:96de605cb66c41541e2e87",
  measurementId: "G-13GBE1PSJL"
};

// Initialize Firebase
const appFirebase = initializeApp(firebaseConfig);


const messaging = getMessaging(appFirebase);


// //
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
// //
// import _ from 'lodash';

  




export default {
  name: "App",
  components: {
    SocketInfo,
    Sidenav,
    // Configurator,
    Navbar,
    AppFooter,
    Cargador,
    pushNoti,
    Error,
    PermisionAlert
  },
  data() {
    return {
      loading: true,
      notifications : [],
      device_token: null,
      auth: null,
      key:1,
      allowConectionSocket: false,
      showAlertNotifications: false
    }
  },
  mounted() {

    


    if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      this.permision_notifications = permission
      if (permission === 'granted') {
        // User granted permission, proceed with getting token
      } else if (permission === 'denied') {
        this.showAlertNotifications = true
        // User denied permission, handle it (e.g., show a message)
        console.log('User denied notification permission');
        // You can display a message or take appropriate action here.
        // this.handleNotificationPermissionDenied();
      } else {
        // alert('parece que no')

        
        // User dismissed the permission prompt
        console.log('Notification permission dismissed');
      }
    });
  } else {
    // Browser doesn't support notifications
    console.log('Browser does not support notifications');
  }
  
  this.getTokenAndPass()

      
    



  },
  methods: {

    getTokenAndPass() {
      var alex = null
      
      getToken(messaging, { vapidKey: 'BImbGlvJDXyr-5AOi19HHjNReoTbTjyMw9tiHaaGwpYOh9vAduHVCDRebVXjP1pL1eNvFFOd-2rLwEHvzS4NzRM' })
      .then((currentToken) => {
        if (currentToken) {
          alex = currentToken;
          this.device_token = alex;
          this.$store.state.device_token = alex
          // Send the token to your server and update the UI if necessary
          console.log('TOKEN de dispositivo',alex)
        
          // ...
        } else {
          // Show permission request UI
          // alert('No tienes permiso para Notificaciones');
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      })
      .finally(() => {
        
        // comprovamos si hay token, cogemos los datos, iniciamos socket y nos conectamos
        if(this.$route.name !== "Sign In")
          this.checkToken()
        else
          this.loading = false
        // this.getGeneralData()


      });
    },
    reloadMain() {
       this.key++
    },
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    // Valida si hay token, en caso negativo enviamos al login y en caso positivo llamamos al /user
    checkToken() {
      var c = cookie.get('authToken')
      if((c == false || c == undefined) && this.$route.name !== "Sign In")
        this.$router.push({ name: 'Sign In' });
      this.validateToken(c)
    },

    validateToken(token) {
      console.log('EOTOKEN',this.device_token)
      this.$store.state.token = token
      
      this.$axios.post(this.$store.state.baseUrl+'/api/user', {
        'device_token': this.device_token
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.$store.state.auth = response.data.data
            this.auth = response.data.data
            this.getGeneralData();
            
            if(this.$route.name == "Sign In")
             this.$router.push({ name: 'Inicio' });
          } else {
            this.$router.push({ name: 'Sign In' });
          }
        })
        .catch(() =>  {
           console.error('ERROR DE TOKEN')
           if(this.$route.name !== "Sign In")
             window.location.href = '/sign-in';
          //  this.$router.push({ name: 'Sign In' });
           
        })
        .finally(()=>{
          
          this.loading = false;
        })

    },
    getGeneralData() {
      // alert('general')
      console.log('get general dta')
      // if(!this.$store.state.auth)
      //   return;
      this.$axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.state.token
      this.$axios.get(this.$store.state.baseUrl+'/api/general', null)
        .then(response => {
          if(response.data.rc == 1) {
            this.$store.state.general = response.data.data
            console.log('GENERAL',this.$store.state.general)
            this.allowConectionSocket = true
            // si todo va bien entonces iniciamos y conectamos sockets
            // this.initSocket()
            // this.connectSocket()
            // si solo tiene una empresa se le asigna al store
            this.assignCompany(response.data.data.companies)
            this.loading = false

          }
        })
        .catch(() =>  {
           console.error('error con formularios y datos')
        })
    },
    assignCompany(companies) {
      if(companies.length == 1) {
        this.$store.state.currentCompany = companies[0]
        this.$store.state.currentCompany_id = companies[0].id

      }
    },


  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>
