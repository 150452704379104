<template>
  <div :ref="'messageReadList' + message.id">
      <div @click="toggleOpen()" class="readed pointer">  
        <svg v-if="this.getReadedUsers().length === num" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
      </div>
      <readbyList  v-if="openList && (this.$store.state.auth.rol == 'asesor')" :message="message" />
  </div>
  
</template>
  
  <script>

import readbyList from "@/views/components/Chats/messages/readByList.vue";
  export default {
    data() {
      return {
        openList: false,
        num: 0
      };
    },
    beforeMount() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener("click", this.handleClickOutside);
    },
    mounted() {
      this.num = this.getUsersCanReadMessage().length
    },
    props: ['message'],
    components: {
      readbyList
    },
    methods: {
      // if(this.$store.state.isReadBy(this.message,this.$store.state.auth.id))
      toggleOpen() {
        if(this.$store.state.isReadBy(this.message,this.$store.state.auth.id))
          this.openList = !this.openList

      },
      getUsersCanReadMessage() {
        const users = this.$store.state.chats.current.users
        return users.filter(u => {
          if(u.rol == 'client' && !this.message.public )
            return false
          else
            return true
        });
      },
      getReadedUsers() {
        var users = this.getUsersCanReadMessage()
        return users.filter(u => this.message.readed_by.includes(u.id));
      },
      
      handleClickOutside(event) {
      if (this.openList) {
        const clickedElement = event.target;
        const messageOptions = this.$refs["messageReadList" + this.message.id];

        if (messageOptions && !messageOptions.contains(clickedElement)) {
          this.openList = false;
        }
      }
    },
    }
  
  };
  </script>
  
  

    
  