<template>
  <ul class="chatListPestana">
     <li @click="this.$store.state.chats.form.filter = 'default'" :class="{ selectedPestana: this.$store.state.chats.form.filter == 'default' }" > Todos </li>
     <li @click="this.$store.state.chats.form.filter = 'tickets'" :class="{ selectedPestana: this.$store.state.chats.form.filter == 'tickets' }"> Asuntos </li>

  </ul>
</template>
  
  <script>


  // import Cargador from "@/components/Cargador.vue";
  
  export default {
    name: "pestana",

    components: {

      // Cargador
    }
    
  
  };
  </script>
  
  

    
  