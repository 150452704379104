<template>
    <div>
      <div v-if="showFilters" class=" pb-0">
        <div class="container-fluid">
          <div class="row d-flex justify-content-between">

            <div class="col-md-6 d-flex">
              <div class="filter m-auto w-100">
                <div class="input-group m-auto">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Buscar"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex ">
              <!-- FILTRO -->
              <div class="m-1">
                <small class="ml-5">Filtrar:</small> <br>
                  <select @change="updateFilter()" v-model="selection" class="form-select selector" >
                    <option value="0">Todos</option>
                    <option  value="1">Pendientes de contestar</option>
                    <option value="2">Contestados</option>
                  </select>
              </div>


            </div>


          </div>

        </div>
      </div>

      <div class="table-responsive p-0 col-12">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell"
              >
                Estado
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 "
              >
                Título Formulario
              </th>
              <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
              >
                Descripción
              </th>
              <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
              >
                Empresa
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Opciones
              </th>
            </tr>
          </thead>
          <Cargador v-if="this.loading" />
          <tbody v-else >
            <h5 v-if="forms.length===0" class="m-4">No hay formularios</h5>
            <tr v-for="form in this.forms"  :key="form.id">
              <td class="d-none d-md-table-cell">
                <!-- Para clientes -->
                <div v-if="this.$store.state.auth.rol == 'client'" class="">
                  <span v-if="form.status == 'success' || form.status == 'answered'"><i class="fa fa-circle me-1 m-1 success"></i>Contestado</span>
                  <span v-if="form.status == 'pending'"><i class="fa fa-circle me-1 m-1 pending"></i>Pendiente</span>
                  <span v-if="form.status == 'error'"><i class="fa fa-circle me-1 m-1 warning"></i>Con Errores</span>
                </div>
                <!-- Asesores -->
                <div v-else>
                  <span v-if="form.status == 'success'"><i class="fa fa-circle me-1 m-1 success"></i>Correcto</span>
                  <span v-if="form.status == 'answered'"><i class="fa fa-circle me-1 m-1 pending"></i>Contestado</span>
                  <span v-if="form.status == 'pending'"><i class="fa fa-circle me-1 m-1 pending"></i>Pendiente de Cliente</span>
                  <span v-if="form.status == 'error'"><i class="fa fa-circle me-1 m-1 warning"></i>Con Errores</span>
                  <br>
                </div>

              </td>
              <td >
                <div class="d-flex px-2 py-1">
                  <span v-if="form.form!==null">{{form.form.title}}</span>
                </div>
              </td>
              <td class=" d-none d-md-table-cell">
                <div class="d-flex px-2 py-1">
                    <span v-if="form.form!==null">{{form.form.description}}</span>
                </div>
              </td>

              <td class=" d-none d-md-table-cell">
                <div  v-if="form.company" class="d-flex px-2 py-1">
                  <router-link
                    :to="{
                      name: 'Empresa',
                      params: {
                        id: form.company.id
                      }
                    }">
                    <span>{{form.company.name}}</span>
                  </router-link>
                </div>
              </td>


              <td>
                <div class="d-flex px-2 py-1">
                  <router-link
                    :to="{
                      name: 'Solicitud de Formulario',
                      params: {
                        id: form.id
                      }
                    }">
                  <vsud-badge class="m-1" color="success" variant="gradient" size="m">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
                    </svg>
                  </vsud-badge>

                </router-link>
                <vsud-badge v-if="this.$store.state.auth.rol !== 'client'" @click="deleteForm(form)" class="m-1 pointer" color="danger" variant="gradient" size="m">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                </vsud-badge>

                </div>
              </td>


            </tr>




          </tbody>
        </table>

      </div>
    </div>

</template>

<script>
import VsudBadge from "@/components/VsudBadge.vue";
import Cargador from "@/components/Cargador.vue";


// import VsudPagination from "@/components/VsudPagination.vue"
// import VsudPaginationItem from "@/components/VsudPaginationItem.vue"

export default {
  name: "forms-table",
  data() {
    return {
      loading:true,
      forms: [],
      selection: 0,
      filtro: null


    };
  },
  props: {
    showFilters:{
      type: Boolean,
      default:false
    },
    filters: {
      default: {
        ticket_id: null,
        status: null,
        from_id: null,
      }

    }
  },
  components: {
    VsudBadge,
    Cargador
    // VsudPagination,
    // VsudPaginationItem
  },
  mounted() {

    this.filtros = this.filters
    this.getTickets()

  },
  methods: {
    deleteForm(formIn){
      if(!confirm("¿Estás seguro que deseas eliminar la solicitud? \n "))
        return ;
      
      
      var self = this
      this.$axios.post(this.$store.state.baseUrl+'/api/request_form/'+formIn.id+'/delete', null)
        .then(response => {
          if(response.data.rc == 1) {
            this.getTickets();
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(

        )
    },
    getTickets(){
      //

      let formData = new FormData();
      if(this.filtros.ticket_id !== null)
        formData.append('ticket_id', this.filtros.ticket_id);
      if(this.filtros.status !== null)
        formData.append('status', this.filtros.status);
      if(this.filtros.from_id !== null)
        formData.append('from_id', this.filtros.from_id);
      if(this.$store.state.currentCompany_id !== null)
        formData.append('company_id', this.$store.state.currentCompany_id);

      
      this.loading=true;
      
      this.$axios.post(this.$store.state.baseUrl+'/api/requests_forms', formData)
        .then(response => {
          if(response.data.rc == 1) {
            console.log('RESPUESTA',response.data.data)
            this.forms = response.data.data
            this.loading = false


          } else {
            this.error = true
          }
        })
        .catch(function () {
            this.error = true
        })
        .finally(()=> {
            this.loading = false
        })
    },
    updateFilter() {
      if(this.selection == 0)
        this.filtros.status = null;
      if(this.selection == 1)
        this.filtros.status = "pending";
      if(this.selection == 2)
        this.filtros.status = "answered";
      this.getTickets()
    }
  }
};
</script>
