<template>
  <div class="conectionInfoAlert">
    <div v-if="status == 'connecting'" class="alert alert-socket">
        <Cargador :width="30"/> Conectando...
    </div>
    <div v-if="status == 'failed'" class="alert alert-danger">
        Parece que ha habido un problema con la conexión Socket 
        <button class="btn btn-link p-0 color-black ms-2" @click="initSocket()">Reintentar</button>
    </div>
  </div>
</template>

<script>

import Cargador from "@/components/Cargador.vue";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import _ from 'lodash';




export default {
  name: "conectionSocket",
  data(){
    return {
      status: 'connecting'
    }
  },
  props: {
    
  },
  components: {
    Cargador
  },
  mounted() {
    this.initSocket()
    // navigator.serviceWorker.addEventListener('message', (event) => {
    //   var routeName = this.$route.name
    //   this.$store.commit('messageFromFirebase', {event, routeName});
    //   console.log('Mensaje recibido mientras la aplicación está en primer plano:', event.data);
    //   // Aquí puedes manejar la notificación según tus necesidades
    // });
    
  },
  methods: {
    initSocket() {
      this.status = 'connecting'
      // como ya tenemos los datos del usuario ya podemos hacer el global de la conexión
      window.Pusher = Pusher;
      window.Echo = new Echo({
        broadcaster: 'pusher',
          key: this.$store.state.socketsKey,
          wsHost: this.$store.state.socketsUrl,
          wssHost: this.$store.state.socketsUrl,
          wsPort: this.$store.state.socketsPort,
          wssPort: this.$store.state.socketsPort,
          disableStats: true,
          cluster:'eu',
          forceTLS: this.$store.state.socketsTLS,
          enabledTransports: this.$store.state.socketsTransPorts,
          authEndpoint: this.$store.state.baseUrl+'/api/broadcasting/auth',
          auth: {
            headers: {
              Authorization: `Bearer `+ this.$store.state.token
            }
          }
      });
      // window.Echo.connector.pusher.connection.bind('connecting', () => {
      // });
      window.Echo.connector.pusher.connection.bind('connected', () => {
        this.$store.state.initSocket = true
        this.status = 'connected'
        this.connectSocket()
      });
      window.Echo.connector.pusher.connection.bind('unavailable', () => {
        this.$store.state.initSocket = false
        this.status = 'failed'
      });
      window.Echo.connector.pusher.connection.bind('failed', () => {
        this.status = 'failed'
        this.$store.state.initSocket = false
      });
      window.Echo.connector.pusher.connection.bind('disconnected', () => {
        this.status = 'failed'
        this.$store.state.initSocket = false
      });


      // console.log(window.Echo)
    },
    connectSocket() {
      console.log('conexion eventsocket')
      this.$store.state.socketChat = window.Echo
          .join("User."+this.$store.state.auth.id)
          .listen('NotificationEvent', (e) => {
            console.log('NotificationEvent',e)
            if(e.type == "chat") {
              // si estammos en un chat
              if(this.$route.name == 'Chat' || this.$route.name == 'Chats') {
                // si el chat ya existe entonces se añade
                var index = _.findIndex(this.$store.state.chats.list, { id: e.chat_id});
                if (index !== -1) {
                  var mess = e.message;
                  mess.user = e.from;
                  if(this.$store.state.chats.current) {
                     // si el chat es dioferente al actual ponngamos alerta en él
                    if(this.$store.state.chats.current.id !== e.chat_id) {
                      this.$store.state.chats.list[index].last_messages.unshift(mess);
                      this.$store.state.chats.list[index].pending_messages++
                      this.$store.state.general.pendingMessages++

                    } else {
                      // estña en el mmismo chat, no hacer nada ya que la noti se pondrá por el current
                    }
                  } else {
                    // está en chats pero no ha elegido actual
                    this.$store.state.chats.list[index].last_messages.unshift(mess);
                    this.$store.state.chats.list[index].pending_messages++
                    this.$store.state.general.pendingMessages++
                  }
                  
                } else {
                  // si no existe en listado de chats entonces recar  gamos listado 
                  this.$store.state.chats.key++ 
                  this.$store.state.general.pendingMessages++
                }
              } else {
                this.$store.state.general.pendingMessages++
              }
              // si no nos enconntramos enn chats simplemente sumamos uno al pendingmessages y notificamos
              // this.notificate(e)
              // this.$store.state.general.pendingMessages++
              try {
                var audio = new Audio('/sound.wav');
                audio.volume = 0.8;   
                audio.play();
              } catch (error) {
                if(error == false)
                  console.log(error)
              }
              

            } else {
              this.notificate(e)
            }
            

          })
          .error((error) => {
            console.error(error);
          });
          
    },
    notificate(notificationIn) {
      const notiUrl = '/'+notificationIn.url
      // si estamos en el propio chat no suena
      if(this.$route.path !== notiUrl ) {
        var audio = new Audio('/sound.wav');
        audio.volume = 0.8;   
        audio.play();
        this.$store.state.notificationsPush.push({
          id: notificationIn.id,
          title: notificationIn.title,
          body: notificationIn.body,
          data: notificationIn.body,
          url:notificationIn.url
        })
      }
        
    },
    
  }

};
</script>
