<template>
  <div class="headerOptions topheader">
    <tableDoc
      v-if="showDocus"
      class="floatDocu"
      :showFolders="false"
      :filters="{
        ticket_id: this.$store.state.chats.current.ticket.id,
        year:null,
        showDepartments: false,
        showFolders: false
        }" />

        <Tickets :company_id="this.$store.state.chats.current.company.id" v-if="showTickets" class="floatDocu"/>

        
    <ul>
     

      <router-link
      
      v-if="this.$store.state.auth.rol == 'asesor' && this.$store.state.chats.current.despacho == false"
        :to="{
          name:'Abrir Asunto',
          query: {
            to_id: this.$store.state.chats.current.company_id
          },
          props: true
        }"
        >
        <li>

          Crear Asunto
        </li>

        </router-link>

        <router-link
          v-if="this.$store.state.chats.current.ticket"
          :to="{
            name:'Ticket',
            params: {
              id: this.$store.state.chats.current.ticket.id
            },
            
          }"
        >
        <li>

          Ver Asunto
        </li>

        </router-link>

        <li v-if="this.$store.state.chats.current.company" @click="showTickets = true; showDocus=false">
          Asuntos de la empresa
      </li>


        <router-link
        v-if="this.$store.state.chats.current.company_id"
        :to="{
          name:'Empresa',
          params: {
                  id: this.$store.state.chats.current.company_id
                },
          props: true
        }"
        >
        <li >

          Ver Empresa
        </li>

        </router-link>
      <li v-if="this.$store.state.chats.current.ticket" @click="showDocus = true; showTickets=false ">
           Documentos del Asunto
      </li>
    

     



    </ul>
  </div>
</template>
  
  <script>
import tableDoc from "@/views/components/Documentacion/table";
import Tickets from "@/views/components/Tickets/table.vue";


  export default {
    name: "currentChat",
    data() {
      return {
        showDocus: false,
        showTickets: false,
      };
    },
    props: ['chat'],
    components: {
      tableDoc,
      Tickets
    },
    // beforeMount() {

    //   document.addEventListener('click', this.handleClickOutside)
    // },

    methods: {
      // handleClickOutside(event) {
      //   const clickedElement = event.target

      //   // comprueba si el elemento clicado está dentro del componente
      //   const isClickedInside = this.$el.contains(clickedElement)

      //   if (!isClickedInside) {
      //     // código para ejecutar cuando se hace clic fuera del componente
      //   }
      // },
      getGeneralName() {
          if(this.chat.ticket)
                return this.chat.ticket.title
          if(this.$store.state.auth.rol == 'client') {
            if(this.chat.asesor_chat)
              return this.chat.asesor_chat.name
          }
          
          if(this.chat.despacho)
            return "Despacho"
          if(this.chat.asesor_chat)
              return this.chat.asesor_chat.name
      },
      getSubname() {
        
          if(this.$store.state.auth.rol == 'client') {
            if(this.chat.ticket)
                return "- Asunto"
            if(this.chat.asesor_chat)
              return "- Asesor"
          } 
          if(this.chat.ticket) {
            if(this.chat.company)
              return "- "+this.chat.company.name
            else
              return "- Innoe"
          }
          if(this.chat.despacho)
            return "- Innoe";
          if(this.chat.asesor_chat)
              return "- Innoe"
          
          
      }
    }
  
  };
  </script>
  
  

    
  