<template>
<a class="m-3" >
    <div class="d-flex">

      <div class="avatar asesor text-center" :class="'avatar-'+size">
        <router-link
              :to="{
                name: 'User',
                params: {
                  id: user.id
                }
              }">
          <div  class="rounded-circle imageAvatar" >
            <img
              :src="url"
              :alt="alt"
            />
          </div>
        </router-link>

        <h6 v-if="span !== false"><span class="badge bg-secondary">{{this.span}}</span></h6>

        <div class="name-asesor text-center mt-1">
          <span class="text-sm w-100"><strong>{{user.name}}</strong></span>
          <br>
          <span v-if="user.rol=='asesor'" class="text-sm">
            <span v-if="user.department">{{user.department.name}}</span>
            <span v-else>Asesor</span>
          </span>
          <span v-else class="text-sm">Cliente</span>

        </div>
      </div>

      <div v-if="user.id !== this.$store.state.auth.id" class="dropdown opcionesAsesor">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
        <div class="buttonOpcionesAsesor text-cennter">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#8493ab" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
          </svg>
        </div>

        </button>
        <ul  class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li  @click="SolChat()"><a class="dropdown-item">Chat</a></li>
          <li><a class="dropdown-item" v-if="user.phone_number !== null" :href="'tel:+34'+user.phone_number">Llamar</a></li>
          <li>
            <router-link
            class="dropdown-item"
            :to="{
              name: 'Solicitar Reunión',

            }"
            >Concertar Reunión
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: 'User',
                params: {
                  id: user.id
                }
              }"
              class="dropdown-item"
              >Perfil
            </router-link>
          </li>
        </ul>
      </div>
    </div>

  </a>

</template>

<script>



export default {
  name: "VsudAvatar",
  data() {
    return {
      url: this.$store.state.baseUrl+"/images/profiles/default.png"
    }
  },
  props: {
    img: {
      type: String,
      default: "/img/team-4.897617b8.jpg"
    },
    alt: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: null,
    },
    shadow: {
      type: String,
      default: null,
    },
    borderRadius: {
      type: String,
      default: null,
    },
    span: {
      default:false
    },
    circular: Boolean,
    user: null
  },
  components: {

  },
  created() {
    if(this.user.profile_image == null)
      this.url = "https://ui-avatars.com/api/?name="+this.user.name+"&background=eaddff&color=21005d&size=75"
    else
      this.url = this.user.profile_image.url+'/100.'+this.user.profile_image.format
  },
  methods: {
    SolChat(){
      this.loading = true;
      
      
      var self = this
      this.$axios.post(this.$store.state.baseUrl+'/api/chat/solicitar', {
        asesor_id: this.user.id,
        company_id: this.$store.state.currentCompany_id

      })
        .then(response => {
          if(response.data.rc == 1) {
            this.$router.push({ path: '/chats/'+response.data.data.id });
          } 
          else if(response.data.rc == 90) {
            alert('Debes seleccionar una empresa para poder abrir chat a asesor')
          }
          else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
            self.loading = false
        })
    },
    // getSize: (size) => (size ? `avatar-${size}` : null),
    // getClasses: (shadow, circular, borderRadius) => {
    //   let shadowValue, circularValue, borderRadiusValue;
    //
    //   if (shadow) {
    //     shadowValue = shadow === "regular" ? "shadow" : `shadow-${shadow}`;
    //   } else {
    //     shadowValue = null;
    //   }
    //
    //   circularValue = circular ? "rounded-circle" : null;
    //
    //   borderRadiusValue = borderRadius ? `border-radius-${borderRadius}` : null;
    //
    //   return `${shadowValue} ${circularValue} ${borderRadiusValue}`;
    // },
  },
};
</script>
