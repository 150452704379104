<template>
  <div id="chatHeader">
    <div v-if="!this.$store.state.chats.current.despacho" class="ContieneParticipantesChat">
      <participant v-for="user in this.$store.state.chats.current.users" :key="user.id" :user="user"/>
    </div>
    <div class="titleChat">
      <div class="h5 text-upperccase">{{ this.getGeneralName() }}</div> <div class="ms-1"> {{ this.getSubname() }} </div>
    </div>
    <div class="optionChat" ref="headerChat">
      <div @click="openOptions = !openOptions" class="containOpenerHeader pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16"><path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path></svg>
      </div>
      <options v-if="openOptions" />
    </div>
  </div>
</template>
  
  <script>

  import participant from "@/views/components/Chats/participant.vue"
  import options from "@/views/components/Chats/header_options.vue"

  export default {
    name: "currentChat",
    data() {
      return {
        openOptions: false,
      };
    },
    props: ['chat'],
    components: {
      participant,
      options
    },
    beforeMount() {

      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
      handleClickOutside(event) {
        const headerChat = this.$refs.headerChat;
        if (headerChat && !headerChat.contains(event.target)) {
          this.openOptions = false;
        }
      },
      getGeneralName() {
        if(this.$store.state.chats.current.ticket) 
            return this.$store.state.chats.current.ticket.title
        // if client
        if(this.$store.state.auth.rol == 'client') {
          // if other user
          if(this.$store.state.chats.current.asesor_chat)
            return this.$store.state.chats.current.asesor_chat.name
          // if not then the company name? 
          if(this.$store.state.chats.current.company)
            return this.$store.state.chats.current.company.name     
          return "Nuevo Chat"
   
        }
        // if asesor
        // despacho
        if(this.$store.state.chats.current.despacho)
          return "Despacho"
        // partner
        if(this.$store.state.chats.current.asesor_chat)
            return this.$store.state.chats.current.asesor_chat.name
        // partner
        if(this.$store.state.chats.current.company)
            return this.$store.state.chats.current.company.name

        return this.getOtherUser().name
      },
      handleKeyDown(event) {
        if (event.key === 'Enter') {
          if (event.ctrlKey || event.metaKey) {
            // this.sendMessage();
          } 
        }
      },
      getOtherUser(){
        var userOut;
        this.$store.state.chats.current.users.forEach(user => {
          if(user.id !== this.$store.state.auth.id)
          userOut = user
        });
        return userOut
      },
      getSubname() {
        
          // if client
        if(this.$store.state.auth.rol == 'client') {
          if(this.$store.state.general.companies.length > 0 && this.$store.state.chats.current.company)
            return ' - '+ this.$store.state.chats.current.company.name
          // if other user
          if(this.$store.state.chats.current.asesor_chat)
            return "Asesor"
          // if not then the company name? 
          if(this.$store.state.chats.current.company)
            return "?"    
          return "Uknown" 
   
        }
        if(this.$store.state.chats.current.ticket) {
          if(this.$store.state.chats.current.company)
          return ' - '+ this.$store.state.chats.current.company.name
          else
            return "Innoe"
        }
        if(this.$store.state.chats.current.company)
          return " - Empresa"
          // return this.$store.state.chats.current.company.name
            
        // if asesor
        // despacho
        if(this.$store.state.chats.current.despacho)
          return "Innoe"
        // partner
        if(this.$store.state.chats.current.asesor_chat)
            return "Asesor"
        return "Usuario"

          
      }
    }
  
  };
  </script>
  
  

    
  