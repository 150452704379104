<template>
    <div>
      <div v-if="loading" class="d-flex">
          <img class="m-auto" src="loading-files.gif" alt="">
        </div>
      
      <div v-else class="mt-3">
        <!-- Migas de pan -->
        <!-- <li  class="breadcrumb-item">
          <a href="#">Home</a>
        </li>
        <li class="breadcrumb-item"><a href="#">Library</a></li>
        <li class="breadcrumb-item active" aria-current="page">Data</li> -->
        <Migas v-if="showFolders" :info="this.current"/>
        <!-- Carpetas -->
        <div v-if="(this.search.length==0 && !this.allSystem) && this.showFolders" class="row">
          <!-- <small class="ml-5">Carpetas:</small> -->

          <!-- Carpeta -->
          <div v-for="folder in this.filterFolders()"  :key="folder.id" class="col-md-2 mt-3">
            <router-link
              :to="{
                name: 'Documentacion',
                params: {
                  folder_id: folder.id
                }

              }">
              <svg xmlns="http://www.w3.org/2000/svg"  width="50" height="50" :fill="folder.color == null? 'currentColor':folder.color" class="bi bi-folder2 w-100 m-auto" viewBox="0 0 16 16">
                <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
              </svg>
              <p v-if="folder.user" class="w-100 text-center m-auto">{{folder.user.name}} {{folder.user.surnames}}</p>

              <p v-else class="w-100 text-center m-auto">{{folder.name}}</p>
          </router-link>
          </div>
        </div>
      </div>




      <div v-if="!firstLoading && this.filterClients().length >0" class="table-responsive p-0 col-12 mt-4">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Título
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 "
              >
                Descripción
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Fecha
              </th>

              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Subido desde
              </th>

              <th
              v-if="this.$store.state.auth.rol !== 'client'"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Cliente
              </th>
              <th
              v-if="this.$store.state.auth.rol !== 'client'"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Visible
              </th>
              <th
              v-if="this.seePath || this.owner_id !== null"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Ruta
              </th>


              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Opciones
              </th>
            </tr>
          </thead>

          <Cargador v-if="loading" />
          <tbody v-else>
            <vsud-badge v-if="false" class="m-1" color="success" variant="gradient" size="m">
              <i class="fa fa-edit me-1 m-1"></i>
            </vsud-badge>
            

            <tr  v-for="document in this.filterClients()"  :key="document.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <router-link
                    :to="{
                      name: 'Archivo',
                      params: {
                        id:document.id
                      }

                    }"
                  >
                  <i class="fa fa-file me-1 m-1"></i>{{document.name}}
                </router-link>
                </div>
              </td>
              <td class="">
                  <p v-if="document.description !== null" class="text-secondary text-xs font-weight-bold">
                    {{document.description}}
                    <span v-for="attribute in document.attributes"  :key="attribute.id" class="badge tagDocu">{{attribute.name}}</span>
                  </p>
                  <p v-else class="salto text-xs">
                    <span v-for="attribute in document.attributes"  :key="attribute.id" class="badge tagDocu">{{attribute.name}}</span>
                  </p>

              </td>

              <td class="align-middle text-center">
                <span v-if="document.created_at !== null" class="text-secondary text-xs font-weight-bold"
                  >
                  {{this.$store.state.dateFormat(document.created_at)}}
                  </span>
                  <span v-else> - </span>
              </td>

              <td class="align-middle text-center">

                <span v-if="document.ticket !== null" class="text-secondary text-xs font-weight-bold">
                  <router-link
                    :to="{
                      name: 'Ticket',
                      params: {
                        id: document.ticket.id
                      }
                    }"
                    class="dropdown-item"
                    >Ticket #000{{document.ticket.id}}
                  </router-link>

                </span>
                <span v-if="this.$store.state.auth.rol == 'asesor' && document.upload_from == 'APL'">
                  APL
                </span>
              </td>


              <td v-if="this.$store.state.auth.rol !== 'client' " class="align-middle text-center">
                <router-link
                v-if="document.company"
                  :to="{
                    name: 'Empresa',
                    params: {
                     id : document.company.id
                    }
                  }"
                  class="dropdown-item"
                  >{{document.company.name}}
                </router-link>
              </td>
              <td v-if="this.$store.state.auth.rol !== 'client'" class="align-middle text-center">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" v-model="document.visible" disabled id="customSwitch2">
                  <!-- <label class="custom-control-label" for="customSwitch2">Disabled switch element</label> -->
                </div>
              </td>
              <td v-if="this.seePath" class="align-middle text-center text-sm">
                {{document.path}}
              </td>

              <td class="align-middle text-center text-sm">
                <router-link
                  :to="{
                    name: 'Archivo',
                    params: {
                      id:document.id
                    }

                  }"
                >
                <vsud-badge class="m-1" color="success" variant="gradient" size="m">
                  <i class="fa fa-edit me-1 m-1"></i>
                </vsud-badge>
              </router-link>


              </td>


            </tr>



          </tbody>


        </table>

      </div>

      <div v-if="!loading && this.documents.length == 0" class="container m-2 m-auto text-center mt-4">
          <!-- <img width="150" src="not-found.gif" alt=""> -->

          <h5 style="opacity: 0.4;">No se han encontrado documentos</h5>

      </div>
    </div>

</template>

<script>
import VsudBadge from "@/components/VsudBadge.vue";
import Cargador from "@/components/Cargador.vue";

import Migas  from "@/components/Migas.vue";



// import VsudPagination from "@/components/VsudPagination.vue"
// import VsudPaginationItem from "@/components/VsudPaginationItem.vue"

export default {
  name: "documents-table",
  data() {
    return {
      firstLoading: true,
      loading:true,
      documents: [],
      departments: [],
      subDepartments:[],
      selection: 0,
      filtro: null,
      error: false,
      years:[],
      showUsersSearch: false,
      migas: [],





    };
  },
  props: {
    showFilters:{ // en desuso
      type: Boolean,
      default:false
    },
    showFolders: {
      type: Boolean,
      default: true
    },
    filters: {
      default: {
        ticket_id: null,
        year: null,
        department_id: null,
        onlyTickets: null,
        showDepartments:true,
        owner_id: null,
      }

    },
    search: {
      default:""
    },
    owner_id: {
      default:null
    },
    keepFolders: {
     default:false
   },
   allSystem: {
     default: false
   },
   seePath: {
     default: false
   },
   attributes: {
     default: null,
     type: Array
   }
  },
  components: {
    VsudBadge,
    Cargador,
    Migas,
  },
  created() {
    this.filtros = this.filters
    this.getDocu()

  },
  methods: {
    filterClients() {
      if(this.owner_id !== null)
        return this.documents.filter(document => {
           return document.company_id == this.owner_id
        })
      else
        return this.documents
    },
    filterFolders() {
      if(this.search !== null && this.search.length >0)
        return this.folders.filter(folder => {
           return folder.name == this.search || folder.name == this.search
        })
      else
        return this.folders
    },
    getDocu(){
      if(this.filters.showDepartments) {
        if(this.search.length >=1)
          this.filtros.showDepartments = false;
        else
          this.filtros.showDepartments = true;
      }

      //

      let formData = new FormData();
      if(this.filtros.ticket_id !== null)
        formData.append('ticket_id', this.filtros.ticket_id);
      if(this.filtros.year !== null)
        formData.append('year', this.filtros.year);
      if(this.filtros.department_id !== null) {
        formData.append('department_id', this.filtros.department_id);
      }
      if(this.filtros.onlyTickets !== null)
        formData.append('onlyTickets', true);
      if(this.search.length>=1) {
        formData.append('search', this.search);
      }
      if(this.owner_id !== null)
        formData.append('owner_id', this.owner_id);
      if(this.allSystem)
        formData.append('allSystem', this.allSystem);
      var tags = (JSON.stringify(this.attributes))
      console.log(tags,'TAGS')
      if(this.attributes!== null && tags.length>0)
        formData.append('tags', tags);
      if(this.$store.state.currentCompany_id !== null)
        formData.append('company_id', this.$store.state.currentCompany_id)

      if(this.$route.params.folder_id !== '' && this.$route.params.folder_id !== undefined)
        formData.append('folder_id', this.$route.params.folder_id);

      this.documents = []
      this.migas = []

      
      this.loading=true;
      
      this.$axios.post(this.$store.state.baseUrl+'/api/documents/filter', formData)
        .then(response => {
          if(response.data.rc == 1) {
            this.documents = response.data.data.documents
            this.folders = response.data.data.folders
            this.current  = response.data.data.current
            // this.teste(this.current);
              // if(this.filtros.showDepartments)
              //   this.subDepartments = response.data.data.subDepartments
              // else
              //   this.subDepartments = []
            // this.years = response.data.data.years
            this.firstLoading = false
            // this.loading = false

          }
        })
        .catch(error => {
          this.error = true
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })

    },
    changeDepartment(department) {
      if(department == 'ofTickets') {
        this.filtros.onlyTickets = true
        this.filtros.department_id = null;
        this.filtros.showDepartments = false
        this.getDocu()
        return true;
      }
      this.filtros.onlyTickets = null
      this.filtros.department_id = department
      if(department == null)
        this.filtros.showDepartments = true
      this.getDocu()
    },
    updateFilter() {
      if(this.selection == 0)
        this.filtros.status = null;
      if(this.selection == 1)
        this.filtros.status = "pending";
      if(this.selection == 2)
        this.filtros.status = "success";
      this.getDocu()
    },
    teste(folder) {
      if(folder == null)
        return;
      this.migas.push(folder)
      if(folder.folders_road !== null)
        this.teste(folder.folders_road)
    }

  }
};
</script>
