<template>
  <div class="chatItem" :class="{ selectedChatItem: this.chatIn.id == this.$route.params.id?? 0 }">

    <router-link
       :to="{
         name: 'Chat',
         params: {
           id: chatIn.id
         }
       }">

      <!-- FOTO -->
      <div class="containAvatarChat">
        <img class="chatAvatar" :src="getImageUrl()" alt="">
      </div>

      <!-- CUERPO -->
      <div class="bodyOfChatItem">
        <span> <text class="text-bold mainTitleChat">{{this.getPersonTitle()}}</text> <small> - {{ this.getType() }}</small></span>
        <p v-if="chatIn.last_messages.length>0" v-html="chatIn.last_messages[0].message"></p>
        <p v-else>  </p>
      </div>
      <!-- Mensajes -->
      <div class="chatItemDetails">
        <small v-if="chatIn.last_messages.length>0">
          {{this.$store.state.dateFormatSmaller(chatIn.last_messages[0].created_at)}}
        </small>
        <small v-else>
          {{this.$store.state.dateFormatSmaller(chatIn.created_at)}}
        </small>
        <div v-if="chatIn.pending_messages >0" class="boubleChatItem">
          <span v-if="chatIn.pending_messages < 100" >{{ chatIn.pending_messages }}</span>
          <span v-else>+99</span>
        </div>
        <!-- <div v-else-if="chatIn.last_messages.length == 0" class="boubleChatItem">
          
        </div> -->

      </div>
      
    </router-link>

  </div>
</template>
  
  <script>

  
  export default {
    name: "chat",
    props: {
      chatIn: {

      }
    },
    data() {
      return {
      };
    },
    // beforeCreate() {

    // },
    // // beforeRouteLeave(to, from,next) {
  
    //   console.log(to,from)

    //   next();

    // },
    methods: {
      getPersonTitle() {
        if(this.chatIn.ticket) 
            return this.chatIn.ticket.title
        // if client
        if(this.$store.state.auth.rol == 'client') {
          // if other user
          if(this.chatIn.asesor_chat)
            return this.chatIn.asesor_chat.name
          // if not then the company name? 
          if(this.chatIn.company)
            return this.chatIn.company.name     
          return "Nuevo Chat"
   
        }
        // if asesor
        // despacho
        if(this.chatIn.despacho)
          return "Despacho"
        // partner
        if(this.chatIn.asesor_chat)
            return this.chatIn.asesor_chat.name
        // partner
        if(this.chatIn.company)
            return this.chatIn.company.name
        return this.getOtherUser().name
      },
      getOtherUser(){
        var userOut;
        this.chatIn.users.forEach(user => {
          if(user.id !== this.$store.state.auth.id)
          userOut = user
        });
        return userOut
      },
      getImageUrl() {
        if(this.chatIn.ticket)
          return "https://ui-avatars.com/api?name=🎟️=0D8ABC&color=fff&size=50&format=svg"
        if(this.$store.state.auth.rol == 'asesor') {
          if(this.chatIn.despacho)
            return "https://ui-avatars.com/api/?name=D&background=eaddff&color=21005d&size=50"
          if(this.chatIn.ticket && this.chatIn.company == null)
            return "https://ui-avatars.com/api/?name=🎟️&background=eaddff&color=21005d&size=50&format=svg"
          if(this.chatIn.ticket && this.chatIn.company)
            return "https://ui-avatars.com/api/?name="+this.chatIn.company.name+"&background=a0a0a0&color=fff&size=50"
        }
        
        // if client
        if(this.$store.state.auth.rol == 'client') {
          // if other user
          if(this.chatIn.asesor_chat)
            return "https://ui-avatars.com/api/?name="+this.chatIn.asesor_chat.name+"&background=eaddff&color=21005d&size=50"
          // if not then the company name? 
          if(this.chatIn.company)
            return "https://ui-avatars.com/api/?name="+this.chatIn.company.name+"&background=eaddff&color=21005d&size=50"
   
        }
        // if asesor
        // despacho
        // if(this.chatIn.despacho)
        //   return "Despacho"
        // partner
        if(this.chatIn.asesor_chat)
          return "https://ui-avatars.com/api/?name="+this.chatIn.asesor_chat.name+"&background=eaddff&color=21005d&size=50"
        // partner
        if(this.chatIn.company)
          return "https://ui-avatars.com/api/?name="+this.chatIn.company.name+"&background=a0a0a0&color=fff&size=50"
        return "https://ui-avatars.com/api/?name="+this.getOtherUser().name+"&background=eaddff&color=21005d&size=50"
      },  
      getType() {
        
        // if client
        if(this.$store.state.auth.rol == 'client') {
          if(this.$store.state.general.companies.length > 0 && this.chatIn.company)
            return this.chatIn.company.name
          // if other user
          if(this.chatIn.asesor_chat)
            return "Asesor"
          // if not then the company name? 
          if(this.chatIn.company)
            return ""    
          return "Uknown" 
   
        }
        if(this.chatIn.ticket) {
          if(this.chatIn.company)
            return this.chatIn.company.name
          else
            return "Innoe"
        }
        if(this.chatIn.company_id)
          return "Empresa"
            
        // if asesor
        // despacho
        if(this.chatIn.despacho)
          return "Innoe"
        // partner
        if(this.chatIn.asesor_chat)
            return "Asesor"
        return "Usuario"
        // 
        // if(this.chatIn.company)
            // return "Empresa"
        
      }
      
    }
  
  };
  </script>
  
  

    
  