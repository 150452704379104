<template>
  <div class="py-4 container-fluid">
    <div v-if="!loading && error" class="alert">
        <div class="alert alert-danger">
          Parece que hay algún error
        </div>
    </div>
    <Cargador v-if="loading"/>


    <div v-if="!loading && !error" class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h1>{{info.title}}</h1>
            <span>{{info.publish_on}}</span>
            <p class="mt-5" v-html="info.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Cargador from "@/components/Cargador.vue";
  

 

  export default {

    data() {
    return {
      loading:true
      }
    },
    created() {
      this.getData()
    },
    components: {
      Cargador
    },
    methods: {
      getData() {
        
        
        this.$axios.get(this.$store.state.baseUrl+'/api/news/'+this.$route.params.id, null)
          .then(response => {
            if(response.data.rc == 1) {
              this.info = response.data.data
              this.loading = false
            } else {
              this.error = true
            }
          })
          .catch(() =>  {
              this.error = true
          })
          .finally(() =>  {
              this.loading = false
          })
      }
    }

  }


</script>