<template>
  <div class="py-4 container-fluid">

    <Cargador v-if="this.loading"/>
    <div v-else-if="!error" class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h3> {{ info.title }} </h3>
            <div v-if="!this.hasCurrentAccepted()" class="alert alert-light ">
              <div class="d-flex justify-content-between">
                <h5>¿Deseas Aceptar la reunión?</h5>
                <div v-if="!this.propose.click">
                  <button @click="send('confirmed')" class="btn btn-primary">Aceptar
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                  </button>
                  <button @click="send('refused')" class="btn btn-secondary ms-2">Rechazar</button>
                  <button @click="this.propose.click = true" class="btn btn-secondary ms-2">Proponer 
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-event ms-1" viewBox="0 0 16 16">
                      <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                    </svg>
                    </button>
                  
                </div>
              </div>
              
              <div class="modal-refuse" v-if="this.propose.click">
                <form  @submit.stop.prevent="sendPropose()" >
                    <div class="form-group">
                      <label>Fecha: {{propose.meet_on}} </label>
                      <input v-model="propose.meet_on" type="date" required class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Hora: </label>
                      <input required v-model="propose.at" step="60" type="time" class="form-control">
                    </div>
                    <div v-if="propose.error" class="alert alert-danger">
                      Parece que ha habido algún error
                    </div>
                    <button type="submit" class="btn btn-primary">
                      <Cargador :width="40" v-if="propose.loading"/>
                      <text v-else>Proponer nueva fecha</text>
                    </button>

                </form>
                  
                </div>
              <!-- <Cargador v-else/> -->
              
            </div>
            <!-- <small>#10291021</small> -->
            <div class="row">
              <div class="col-md-6">
                <div class="mt-2">
                  <strong>Estado: </strong> 
                  <text v-if="info.status == 'pending'"> PENDIENTE DE CONFIRMACIÓN</text>
                  <text v-if="info.status == 'confirmed'"> CONFIRMADA</text>

                </div>
                <div class="mt-2">
                  <strong>Ubicación: </strong> <text> {{ info.ubication }}</text>
                </div>
                <div class="mt-2" v-if="info.ubication_datails">
                  <strong>Detalle Ubicación: </strong> <text>{{ info.ubication_datails }}</text>
                </div>
                <div class="mt-2">
                  <strong>Fecha: </strong> <text>{{info.meet_on}} <br> a las {{info.at}}</text>
                </div>
                <div v-if="info.ticket" class="mt-3">
                  <strong>Asunto</strong>: {{ info.ticket.title }}
                  <br>
                  <router-link 
                      :to="{
                        path:'/tickets/'+this.info.ticket_id,
                        }" >
                      <button class="btn btn-info">
                        Asunto asociado #00{{ this.info.ticket_id }}
                      </button>
                    </router-link>
                </div>
                <div class="mt-2">
                  <strong>Descripción/detalles:</strong>
                  <div class="form-group m-1  mt-2">
                    <textarea v-model="info.description" disabled class="form-control" rows="3"></textarea>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label>Empresa: </label>

                <AvatarCompany
                  v-if="info.company"
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :span="'Empresa'"
                  :company="info.company"
                  :circular="true"
                  class="m-auto"
                />
              </div>

              <div class="col-md-12">
                <hr>

                <label>Asesores: </label>
                <div class="contieneAsesoresScroll">
                  <vsud-avatar
                  v-for="asesor in info.users"
                  :key="asesor.id"
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :span="translatePivot(asesor.pivot)"
                  :user="asesor"
                  :circular="true"
                />
                </div>
                
              </div>
               
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="error" class="alert alert-danger">
      <strong>Vaya</strong> parace que ha habido algun erorr. 
    </div>
    
  </div>
</template>
<script>

import Cargador from "@/components/Cargador.vue";

import VsudAvatar from "@/components/VsudAvatar.vue";
import AvatarCompany from "@/components/VsudAvatarCompany.vue";


export default {
  name: "dashboard-default",
  data() {
    return {
      info:{
      },
      loading: true,
      sending: false,
      error: false,
      propose:{
        click: false,
        meet_on: null,
        at: null,
        error: false,
        loading: false
      }
    }
  },
  created(){
    this.load();
  },
  components: {
    Cargador,
    AvatarCompany,
    VsudAvatar

    // VsudAvatar
  },
  methods: {
    load() {
      this.loading = true;
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/meet/'+this.$route.params.id,{
      }).then(response => {
          if(response.data.rc == 1) {
            this.info = response.data.data
            

          } else {
            // this.error = true
          }
        })
        .catch(() => {
          this.error = true
        })
        .finally(() =>  {
            this.loading = false
        })
    },
    translatePivot(pivot) {
      if(pivot.status == 'confirmed')
        return "Aceptado"
      if(pivot.status == 'refused')
        return "Rechazado"
      if(pivot.status == 'pending')
        return "Pendiente"
    },
    send(status) {
      this.sending = true;
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/meet/'+this.$route.params.id+'/status',{
        status: status
      }).then(response => {
          if(response.data.rc == 1) {
            this.load();
          } else {
            this.error = true
          }
        })
        .catch(() => {
          this.error = true
        })
        .finally(() =>  {
            this.sending = false
        })
    },
    sendPropose(){
      if(this.propose.loading)
        return;

      this.propose.loading = true;
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/meet/'+this.$route.params.id+'/propose', this.propose).then(response => {
          if(response.data.rc == 1) {
            this.load();
          } else {
            this.propose.error = true
          }
        })
        .catch(() => {
          this.propose.error = true
        })
        .finally(() =>  {
            this.propose.loading = false
        })
    },
    hasCurrentAccepted() {
      var f = false
      if(this.$store.state.auth.rol == 'client')
        return true;
      else
        this.info.users.forEach(user => {
          if(user.id == this.$store.state.auth.id) {
            if(user.pivot.status == 'confirmed')
              f = true
          }

        });
      return f;
    },
    isAdmin(pivot) {
      if(pivot.admin)
        return "admin"
      return false;
    },

  
  }
};
</script>
