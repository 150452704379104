<template>
  <div class="py-4 container-fluid mt-3">
    <div v-if="error" class="alert">
      <div class="alert alert-danger">
        Parece que hay algún error
      </div>
    </div>
    <div v-if="success" class="alert">
      <div class="alert alert-success">
        Formulario enviado Correctamente
      </div>
    </div>
    <Cargador v-if="this.loading"/>

    <div v-if="!success" class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h3>Requerir Formulario</h3>
            <p>Requiere un formularios a los clientes.</p>
            <div v-if="this.form.ticket_id" class="col-md-12">
              <router-link 
              target="_blank"
                :to="{
                  path:'/tickets/'+this.form.ticket_id,
                  }" >
                <button type="button" class="btn btn-link p-0">
                  Asunto asociado #00{{ this.form.ticket_id }}
                </button>
              </router-link>
            </div>
            <form @submit.stop.prevent="send()">
              <div class="row mt-2">

                <div v-if="!this.form.ticket_id" class="col-md-6">
                  <searchUsers :to_id="form.company_id" :requiredClient="true" :onlyClients="true"  v-on:update:to_id="form.company_id = $event" v-on:update:users_ids="form.users_ids = $event"  :users_ids="form.users_ids"/>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Formulario</label>
                    <select required v-model="form.form_id" class="form-select">
                      <option :value="0">Ninguno</option>
                      <optgroup v-for="(department,index) in this.$store.state.general.forms"  :key="department.id" :label="index">
                        <option  v-for="form in this.$store.state.general.forms[index]"  :key="form.id" :value="form.id">{{form.title}}</option>
                      </optgroup>
                    </select>
                  </div>
                </div>
                <button  type="submit" class="btn btn-secondary" name="button">Enviar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cargador from "@/components/Cargador.vue";
import searchUsers from "@/components/SearchUsers.vue";



export default {
  name: "Requerir Formulario",
  data() {
    return {
      loading:false,
      error:false,
      success: false,
      form: {
        ticket_id: null
      }
    }
  },
  created() {
    if(this.$route.query.ticket_id)
      this.form.ticket_id = this.$route.query.ticket_id
  },
  methods: {
    send() {
      if(this.form.form_id == 0) {
        alert('Selecciona un formulario válido')
        return
      }
      if(this.loading)
        return;

      this.error = false


      this.loading = true

      
      
      this.$axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
      this.$axios.post(this.$store.state.baseUrl+'/api/request_form',this.form)
      .then(response => {
        if(response.data.rc == 1) {
          this.success = true
        } else {
          this.error = true
        }
      })
      .catch(() =>  {
          this.error = true
      })
      .finally(() =>  {
          this.loading = false

      })

    }
  },
  components: {
    Cargador,
    searchUsers

  },

};
</script>
