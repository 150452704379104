<template>
  <div v-if="$store.state.initSocket && $store.state.chats.list.length > 0" >
    <div v-if="this.error" class="alert alert-white">
      <h5>Parece que ha habido algún problema</h5>
      <img src="/cartel.png" alt="">
    </div>
    <div v-if="!loading && !this.error && this.$store.state.chats.current">
      <headerChat/>
      <messages/>
      <writter/>
    </div>
    <Cargador v-if="loading"/>
    
    
  </div>

</template>
  
  <script>


  import headerChat from "@/views/components/Chats/header.vue"
  import messages from "@/views/components/Chats/messages/index.vue"
  import writter from "@/views/components/Chats/writter/index.vue"
  import _ from 'lodash';
  
  import Cargador from "@/components/Cargador.vue";

  export default {
    name: "currentChat",
    data() {
      return {
        loading: true,
        error: false
      };
    },
    unmounted() {
      if(this.$store.state.chats.current)
        window.Echo.leave("Chat."+this.$store.state.chats.current.id)

    },
    components: {
      headerChat,
      Cargador,
      messages,
      writter
    },
    beforeCreate() {

    },
    mounted() {
      this.askToServerAndAddChat()
    },

    methods: {
      // searchById() {
      //   const chatId = parseInt(this.$route.params.id);

      //   // Utilizamos Lodash para buscar el objeto con el chat_id especificado.
      //   const finded = _.find(this.$store.state.chats.list, { id: chatId });
      //   if(finded) {
      //     console.log('Finded ')

      //     this.$store.state.chats.current = finded
      //     this.loading = false;
      //     this.connectToSocket()
      //   }
      //   else {
      //     this.askToServerAndAddChat()
      //   }
      // },
      askToServerAndAddChat(){
          this.$axios.post(this.$store.state.baseUrl+'/api/chat/'+this.$route.params.id)
          .then(response => {
            if(response.data.rc == 1) {

              this.$store.state.chats.current = response.data.data;
              var index = _.findIndex(this.$store.state.chats.list, { id: response.data.data.id });
                if (index !== -1) {
                  this.$store.state.chats.current = this.$store.state.chats.list[index] = response.data.data;
                  // this.$store.state.chats.current = this.$store.state.chats.list[index]
                } 
                this.connectToSocket()

            }
          })
          .catch((error) => {
              // if(!this.error) // evitar recursividad
              //   this.searchById()
              console.error(error)
              this.error = true
          })
          .finally( () => {
              this.loading = false
          })
      },
      connectToSocket() {
        console.log('conected to socket')
         window.Echo
          .join("Chat."+this.$store.state.chats.current.id)
          .listen('MessageSended', (e) => {
            console.log('MessageSended',e)
            // si es de el mismo o
            if ((e.user_id !== this.$store.state.auth.id) || (e.document_id)){
              if(this.$store.state.auth.rol !== 'client' || e.public) {
                
                this.$store.state.chats.current.last_messages.unshift(e)
                // se vuelve a verificar porque en caso de que sea documento no hace falta añadir notificacion al mismo usuario que lo envía
                if(e.user_id !== this.$store.state.auth.id && !e.from_system) {
                  this.$store.state.chats.current.pending_messages++;
                  this.$store.state.general.pendingMessages++;
                }
                
              }
                this.$store.state.scrollDown('containMessages')
            }
          })
          .listen('ReadedEvent', (e) => {
            var index = _.findIndex(this.$store.state.chats.current.last_messages, { id: e.id });
              if (index !== -1) {
                this.$store.state.chats.current.last_messages[index] = e;
              } else {
                this.$store.state.chats.current.last_messages.unshift(e)
              }
            
          })
      }
    }
  
  };
  </script>
  
  

    
  