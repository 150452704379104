<template>
    <div class="headerOptions readByList">
      <span>Leído por: </span>
      <ul>
        <li v-for="user in getUsersRead()" :key="user.id" > {{ user.name }} </li>
      </ul>
      <span>No leído por </span>
      <ul>
        <li v-for="user in getUserDontread()" :key="user.id" > {{ user.name }} </li>
      </ul>
  </div>
</template>
  
  <script>


  export default {
    data() {
      return {
        users: [],
      };
    },
    props: ['message'],
    created() {
      this.users = this.$store.state.chats.current.users
    },

    methods: {
      getUserNameById(id) {
        const usuario = this.message.users.find(u => u.id === id);
        return usuario ? usuario.nombre : 'Usuario no encontrado';
      },
      getUsersRead() {
        return this.users.filter(u => this.message.readed_by.includes(u.id));
      },
      getUserDontread() {
        return this.users.filter(u => {
          if(u.rol !== 'client' || this.message.public )
            return !this.message.readed_by.includes(u.id)
        });
      },
      // filter(ticket => {
      //   if(this.selection == 'all')
      //    return true
      //   if(this.selection == 'pending')
      //     return (ticket.status == 'pending' || ticket.status == 'error' || ticket.status == 're-open')
      //   if(this.selection == 'closed')
      //     return ticket.status == 'done'
      // })

    }
  
  };
  </script>
  
  

    
  