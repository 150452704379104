<template>
    <div class="py-4 container-fluid ">
      <div v-if="!loading && error" class="alert">
        <div class="alert alert-danger">
          Parece que hay algún error
        </div>
      </div>
      <div v-if="!loading && !error" class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <!-- info general -->
                <div class="col-md-6">
                  <h6 class="mt-auto mb-auto">Información</h6>

                  <h6>  #000{{ticket.id}}</h6>
                  Título:<h6> {{ticket.title}}</h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-calendar-range-fill mr-1" viewBox="0 0 16 16">
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
                  </svg>
                  <h6 v-if="ticket.expiration_on !== null">
                    Caduca el {{ticket.expiration_on}}
                  </h6>
                  <div v-else><span>Sin fecha de expiración</span> <br></div>
                  <span>Creado el {{this.$store.state.dateFormat(ticket.created_at)}}</span> <br>
                  <span>Actualizado el {{this.$store.state.dateFormat(ticket.updated_at)}}</span> <br>
                  <hr>
                  <h6>Descripción</h6>
                  <p>
                    {{ticket.description}}
                  </p>
                  <div class="mt-3">
                    <router-link  
                    class="btn btn-info"
                     :to="{
                      name: 'Chat',
                      params: {
                        id: ticket.chat_id
                      }
                    }">
                    Chat

                    </router-link>
                  </div>

                </div>
                <!--  -->
                <div class="col-md-6">
                  <div v-if="ticket.company">
                    Empresa:
                    <avatarCompany 
                  v-if="ticket.company"
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :company="ticket.company"
                  :circular="true"
                  />
                    
                    
                  </div>
                  <div v-else>
                    <h6>Asunto Sin empresa asociada</h6>
                  </div>
                  <span>Estado:</span>

                  <div class="" v-if="this.$store.state.auth.rol !== 'client'">
                    <button
                      class="btn btn-secondary dropdown-toggle m-2"
                      type="button"
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                    <span v-if="ticket.status == 'done'"><i class="fa fa-circle me-1 m-1 success"></i>Resuelto</span>
                    <span v-else><i class="fa fa-circle me-1 m-1 pending"></i>Pendiente</span>
                    <Cargador v-if="loadingChanges" />
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                      <li><a @click="changeStatus('done')" class="dropdown-item" href="#">Resuelto</a></li>
                      <li><a @click="changeStatus('pending')" class="dropdown-item" href="#">Pendiente</a></li>
                      <!-- <li><a @click="changeStatus('error')" class="dropdown-item" href="#">Error</a></li> -->
                    </ul>
                  </div>

                  <div v-else>
                    <span v-if="ticket.status == 'done'"><i class="fa fa-circle me-1 m-1 success"></i>Resuelto</span>
                    <span v-else><i class="fa fa-circle me-1 m-1 pending"></i>Pendiente</span>

                  </div>
                  
                  
                  
                  

                  <div v-if="this.$store.state.auth.rol !== 'client'" class="mt-3">
                   Tiempo dedicado
                      <p>
                        <strong>Total:</strong>
                         {{ticket.total_time_human}}
                      </p>
                      <p v-if="ticket.today_timing !== null">
                        <!-- <strong>Última entrada de tiempo de Hoy:</strong> {{ticket.today_timing.minuts}} minutos -->
                      </p>
                      <p v-else>
                        Hoy no has añadido minutos
                      </p>
                      <Cargador v-if="this.loadingTiming"/>
                      <div v-else class="form-group">
                        <label class="" for="">Añadir minutos Hoy: </label>
                        <input v-on:keyup.enter="addTimming($event)" v-model="this.timingIn" placeholder="Num Minutos" class="form-control"  >
                      </div>
                  </div>



                </div>

              </div>




              


              

            </div>
          </div>



          <div class="card mt-4">
              <div class="card-body">
                <h6>Participantes</h6>
                <div class="contieneAsesoresScroll mt-4">
                  <vsud-avatar
                    v-for="asesor in ticket.asesores"
                    :key="asesor.id"
                    size="xl"
                    border-radius="lg"
                    alt="user1"
                    :span="isAdmin(asesor.pivot)"
                    :user="asesor"
                    :circular="true"
                  />
                


                </div>
                <!-- Info de cliente -->
                <div v-if="ticket.client && this.$store.state.auth.id !== ticket.client.id" class="col-md-12 mt-3">
                  <h6>Cliente:</h6>
                  <vsud-avatar
                    size="xl"
                    border-radius="lg"
                    alt="user1"
                    :span="isAdmin(ticket.client)"
                    :user="ticket.client"
                    :circular="true"
                  />
                </div>
                <!-- Info de la empresa -->
              </div>

            </div>














          
          





        </div>
        <div class="col-md-4">
          <div>
            <h6>
              <div class="w-100 d-flex justify-content-between">
                <div class="m-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-range-fill ml-2" viewBox="0 0 16 16">
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
                  </svg>
                  Reuniones 
                </div>
                <button @click="requestMeet()" type="button" class="btn btn-secondary m-auto" name="button">Crear Reunión</button>
              </div>
              
            </h6>


            <miniMeet v-for="meet in this.ticket.next_meets" :key="meet.id" :meet="meet"></miniMeet>
            <router-link
            :to="{
              path:'/meets'
              }"

              class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
            >
              Todas las reuniones
              <i
                class="fas fa-arrow-right text-sm ms-1"
                aria-hidden="true"
              ></i>
            </router-link>
          </div>
          

          <div v-if="this.$store.state.auth.rol !== 'client'" class="card mt-4">
            <div class="card-body">
              <div  class="">
                <div class="">
                  <h6>Notas privadas </h6>
                  <span>(Solo visible a Asesores)</span>
                  <div class="form-group mt-2">
                   <textarea class="form-control"  v-model="ticket.private_notes" id="exampleFormControlTextarea1" rows="8"></textarea>
                   <button @click="this.update()" type="button" class="btn btn-info mt-4" name="button">Guardar   <Cargador v-if="loadingChanges" /> </button>
                 </div>
                </div>
              </div>
            </div>



            
          </div>
        </div>
        <div class=" mt-4" id="documents">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="w-100 d-flex justify-content-between ">
                    <h6 class="mt-auto mb-auto">Documentos</h6>
                    <button @click="openUploader=true" type="button" class="btn btn-secondary" name="button">Añadir Documento</button>
                    <div  v-if="openUploader" class="floatingPage">
                      <div class="d-flex pointer">
                        <svg @click="openUploader=false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x-circle-fill closeButton" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/></svg>
                      </div>
                      <UploadDocument @uploaded="this.openUploader = false; docuKey++" :nonredirect="true" :ticket="ticket"  />
                    </div>
                  </div>
                  <tableDoc
                  :key="docuKey"
                  :showFolders="false"
                  :filters="{
                    ticket_id: ticket.id,
                    department_id: null,
                    year:null,
                    showDepartments: false,
                    showFolders: false
                    }" />
                </div>
              </div>
            </div>
          </div>
          <div class=" mt-4" id="requestForms">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="w-100 d-flex justify-content-between ">
                    <h6 class="mt-auto mb-auto">Formularios</h6>
                    <router-link :to="{
                      name: 'Requerir Formulario',
                      query: {
                        ticket_id: ticket.id
                      }
                    }">

                    <button @click="addDocument()" type="button" class="btn btn-secondary" name="button">Requerir formulario</button>
                  </router-link>

                  </div>
                  <RquestsForms
                  class="mt-3"
                  :filters="{
                    ticket_id: ticket.id,
                    from_id: null,
                    status: null
                    }" />
                </div>
              </div>
            </div>
          </div>
      </div>

      

    </div>
</template>

<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
import Cargador from "@/components/Cargador.vue";
import RquestsForms from "@/views/components/Forms/table.vue";
import tableDoc from "./components/Documentacion/table";
import miniMeet from "@/views/components/Tickets/miniMeet";
import AvatarCompany from "@/components/VsudAvatarCompany.vue";
import UploadDocument from "@/views/uploadDocument.vue";


export default {
  name: "TicketView",
  data() {
    return {
      ticket: {},
      loading: true,
      loadingChanges: false,
      error:false,
      showForms:false,
      showDocuments: false,
      timingIn: "",
      loadingTiming: false,
      openUploader: false,
      docuKey: 0,


    }
  },
  components: {
    Cargador,
    VsudAvatar,
    RquestsForms,
    tableDoc,
    miniMeet,
    AvatarCompany,
    UploadDocument
  },
  created(){
    this.getData()
  },
  methods: {
    isAdmin(pivot) {
      if(pivot.admin)
        return "admin"
      return false;
    },
    getData() {
      
      
      this.$axios.get(this.$store.state.baseUrl+'/api/tickets/'+this.$route.params.id, null)
        .then(response => {
          if(response.data.rc == 1) {
            this.ticket = response.data.data
            this.loading = false
          } else {
            this.error = true
          }
        })
        .catch(() =>  {
            this.error = true
        })
        .finally(() =>  {
            this.loading = false
        })
    },
    requestMeet(){
      if(this.ticket.next_meets.length>=1) {
        if(!confirm('Ya tienes una reunión asignada. ¿estás seguro de solcitar otra?'))
          return true
      }
      this.$router.push({
        name: 'Solicitar Reunión',
        query: {
          ticket_id: this.ticket.id
          },
          props: true
        })


      // window.location.href = '/meets/request';
    },
    changeStatus(status) {
      this.loadingChanges = true;
      this.ticket.status = status;
      this.update();
    },
    update() {
      this.loadingChanges = true
      
      
      var self = this
      this.$axios.post(this.$store.state.baseUrl+'/api/tickets/'+this.$route.params.id+'/update', this.ticket)
        .then(response => {
          if(response.data.rc == 1) {
            self.ticket = response.data.data
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(
          this.loadingChanges = false
        )
    },
    addTimming(event) {
      this.loadingTiming = true
      
      
      var self = this
      this.$axios.post(this.$store.state.baseUrl+'/api/tickets/'+this.ticket.id+'/timing', {
        minuts: event.target.value
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.ticket.total_time = response.data.data.total_time
            this.ticket.total_time_human = response.data.data.total_time_human
            this.ticket.today_timing = response.data.data.today_timing
            this.timingIn = null
            
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(
          this.loadingTiming = false
        )
    },
  }

};
</script>
