<template>
  <div id="chatList">
    <!-- LOADING -->
    <div v-if="this.loading && this.$store.state.chats.list.length == 0" class="loadingList">
     <img src="/loading-list.gif" alt=""> 
     <img src="/loading-list.gif" alt=""> 
     <img src="/loading-list.gif" alt=""> 
     <img src="/loading-list.gif" alt=""> 
    </div>
    <!-- LIST -->
    <div v-else>
      <!-- <Cargador v-if="this.loading && this.$store.state.chats.list.length > 0"   :width="60"/> -->
        <!-- {{ this.$store.state.chats.form }} -->

      <!-- BUSCADOR -->
      <div class="input-group rounded filterChats">

        <input v-model="this.$store.state.chats.form.search" type="Buscar" class="form-control rounded searcherchats" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
        <div id="searcherIconChat" ref="searcherIconChat" class="pointer">
          <Cargador v-if="this.loading && this.$store.state.chats.list.length > 0"   :width="30"/>
            <div v-else @click="filterOpen = !filterOpen" class="cotainButtonnChatFilter">
              <svg  height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M41.2 64C18.5 64 0 82.5 0 105.2c0 10.4 3.9 20.4 11 28.1l93 100.1v126c0 13.4 6.7 26 18 33.4l75.5 49.8c5.3 3.5 11.6 5.4 18 5.4c18 0 32.6-14.6 32.6-32.6v-182l93-100.1c7.1-7.6 11-17.6 11-28.1C352 82.5 333.5 64 310.8 64H41.2zM145.6 207.7L56.8 112H295.2l-88.8 95.7c-4.1 4.4-6.4 10.3-6.4 16.3V386.8l-48-31.7V224c0-6.1-2.3-11.9-6.4-16.3zM344 392c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H344zM320 256c0 13.3 10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H344c-13.3 0-24 10.7-24 24zM408 72c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H408z"/></svg>

            </div>
            <div class="headerOptions searcherChats" v-if="filterOpen">
            <ul>
              <li>
                <div @click="this.$store.state.chats.form.notReaded = !this.$store.state.chats.form.notReaded" class="form-check">
                  <input  :checked="this.$store.state.chats.form.notReaded" class="form-check-input" id="notReaded" type="checkbox"  >
                  <label @click="this.$store.state.chats.form.notReaded = !this.$store.state.chats.form.notReaded" class="form-check-label" for="notReaded">
                    Chats sin leer
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        
      </div>

      <!-- PESTAÑAS -->
      <pestana/>

      
      <!-- CHATS -->
      
      <!--<chat v-for="chat in  this.filterChats()" :key="chat.last_messages[0]?? chat" :chatIn="chat"></chat>-->
      <chat v-for="chat in  this.filterChats()" :key="chat" :chatIn="chat"></chat>
      <div v-if="this.filterChats().length == 0" class="p-4">
        <h6>No hay resultados</h6>
        <img width="150" class="chatnotFounds" src="/not-found.gif" alt="">
      </div>
    </div>
    
    
  </div>
    

</template>
  
  <script>
  import chat from "@/views/components/Chats/list/chat.vue"
  import pestana from "@/views/components/Chats/list/pestana.vue"
  import _  from 'lodash';
  import Cargador from "@/components/Cargador.vue";
  
  export default {
    name: "listChats",
    data() {
      return {
        loading: true,
        error: false,
        filterOpen: false
      };
    },
    components: {
      chat,
      pestana,
      Cargador
    },
    mounted() {
      this.getChats();
    },
    beforeMount() {

      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener("click", this.handleClickOutside);
    },

    methods: {
      handleClickOutside(event) {
        const headerChat = this.$refs.searcherIconChat;
        if (headerChat && !headerChat.contains(event.target)) {
          this.filterOpen = false;
        }
      },

      getChats() {
        var self = this
        this.$axios.post(this.$store.state.baseUrl+'/api/chats', {
          company_id: this.$store.state.currentCompany_id
        })
        .then(response => {
          if(response.data.rc == 1) {
            // response.data.data.last_messages = response.data.data.last_messages.reverse()
            this.$store.state.chats.list = response.data.data
            // sincronizamos el actual con el de la lista (actual)
            if(this.$store.state.chats.current) {
              var index = _.findIndex(this.$store.state.chats.list, { id: this.$store.state.chats.current.id });
              if (index !== -1) {
                this.$store.state.chats.current = this.$store.state.chats.list[index]
              }
            }
            
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
            self.loading = false
        })
      },
      filterChats() {
        var c = this.$store.state.chats.list
        // buscador
        if (this.$store.state.chats.form.search.length > 0) {
          c = c.filter(chat => {
            const search = this.$store.state.chats.form.search.toLowerCase();
            // por mennsajes
            // por usuarios
            const mensajes = chat.last_messages.filter(mes => {
              const texto = mes.message ? mes.message : '';
              return texto.includes(search);
            });
            // por usuarios
            const users = chat.users.filter(user => {
              const name = user.name ? user.name.toLowerCase() : '';
              const email = user.email ? user.email.toLowerCase() : '';
              return name.includes(search) || email.includes(search);
            });
            
            // por company
            const company = chat.company && chat.company.name ? chat.company.name.toLowerCase() : '';
            // por ticket
            const ticket = chat.ticket && chat.ticket.title ? chat.ticket.title.toLowerCase() : '';

            return users.length > 0 || company.includes(search) || ticket.includes(search) || mensajes.includes(search);
          });
        }
        if (this.$store.state.chats.form.notReaded) 
          c = c.filter(chat => {
            return chat.pending_messages > 0
          })
        if(this.$store.state.chats.form.filter == 'tickets')
          c = c.filter(chat => {
            return chat.ticket !== null
          })

       c = _.orderBy(c, (chat) => {
            // Usamos maxBy para encontrar la fecha más reciente en los mensajes de cada chat
            const lastMessage = chat.last_messages[0] ?? false// ?? new Date(lastMessage.created_at) 
            // Devolvemos la fecha más reciente como valor de ordenación
            if(lastMessage) {
              return new Date(lastMessage.created_at) 
            } else {
              return new Date(chat.created_at)
            }
            // return lastMessage ? new Date(lastMessage.created_at) : chat.created_at?? new Date(0); // Fecha mínima si no hay mensajes
          }, 'desc');

        return c;
      }
    }
  
  };
  </script>
  
  

    
  