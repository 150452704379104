<template>
  <div v-if="this.$store.state.auth" class=" mt-3 container-fluid">
	<div class="row clearfix">
	    <div class="col-lg-12">
	        <div id="chatApp" class="card chat-app">
            <list :key="this.$store.state.chats.key"/>
	          <router-view v-if="$store.state.initSocket && $store.state.chats.list.length > 0" :key="$route.fullPath"  class="currentChat" />

	        </div>
	    </div>
	</div>
</div>
</template>

<script>


// import VsudPagination from "@/components/VsudPagination.vue"
import list from "@/views/components/Chats/list"
import { mapMutations } from "vuex";

export default {
  name: "chats",
  data() {
    return {
    };
  },
  mounted() {
    // subimos el scroll hacia arriba
    document.documentElement.scrollTop = 0; // Para navegadores modernos
    document.body.scrollTop = 0; // Para navegadores más antiguos
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
      }, 100);
  },
  unmounted() {
    document.body.style.overflow = 'scroll';
    if(!this.$store.state.isPinned)
      this.navbarMinimize();
  },
  created() {
    if(this.$store.state.isPinned)
      this.navbarMinimize();
  },
  components: {
    list,
    // Cargador
    // VsudPaginationItem
  },
  beforeCreate() {
    // console.log(this.$store,'Store')
    if(this.$store.state.auth.rol == 'client' && this.$store.state.currentCompany_id == null) {
        this.mustSelect = true;
    }
  },
  beforeRouteLeave(to, from,next) {
    // if(!this.$store.state.isPinned)
    //   this.navbarMinimize();

    console.log(to,from)
    this.$store.state.socketChat = false
    next();
    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"])

  }

};
</script>




