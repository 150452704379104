<template>
  <div>
    <div id="containMessages" ref="containMessages" >
      <!-- {{ this.$store.state.chats.current.last_messages }} -->
      <message
        v-for="message in this.$store.state.chats.current.last_messages.slice().reverse()"
        :message="message"
        :key="message"
      />
    </div>
  </div>
</template>

<script>
import message from "@/views/components/Chats/messages/message.vue";


export default {
  name: "messages",
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      // Hacer un scroll hasta abajo del div con ID "containMessages"
      const containMessages = this.$refs.containMessages;
      if (containMessages) {
        containMessages.scrollTop = containMessages.scrollHeight;
      }
    });
  },
  components: {
    message,
  },
};
</script>
