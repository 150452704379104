<template>
  <span v-if="!loading">{{ this.filteredItems().length }} resultados</span>

  <div class="table-responsive p-0 col-12">
    <Cargador v-if="this.loading"/>
    <div v-if="this.error" class="alert alert-danger m-3" role="alert">
      Parece que ha habido algún error
    </div>
    <div v-if="showFilters" class=" pb-0">
      <div class="container-fluid">
        <div class="row d-flex justify-content-between">

          <div class="col-md-6 d-flex">
            <div class="filter m-auto w-100">
              <small class="ml-5">Buscar:</small> <br>
              <div class="input-group m-auto">
                <span class="input-group-text text-body">
                  <i class="fas fa-search" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar"
                  v-model="form.search"
                  v-on:keyup.enter="getUsers()"
                />
              </div>
            </div>
          </div>
          
          


        </div>

        <div class="row">
          <div class="col-md-12 mt-3 ">
            <div v-for="service in this.services"  :key="service.value"  class="form-check form-check-inline">
              <input v-model="form.services" class="form-check-input" type="checkbox" :value="service.value" :id="'check'+service.value">
              <label  class="form-check-label" :for="'check'+service.value">
                {{service.label}}
              </label>
           </div>
           <div class="form-check form-check-inline">
              <input v-model="form.allUsers" class="form-check-input" type="checkbox" @change="getUsers()" :value="true" id="checkAll">
              <label  class="form-check-label" for="checkAll">
                Ver Todas <small>(Incluyendo las que no tengo asignadas)</small>
              </label>
           </div>
           <Cargador v-if="this.loadingUsersAll"/>


          </div>
        </div>

        

      </div>
    </div>

    <table v-if="!loading" class="table align-items-center mb-0 mt-4">
      <thead>
        <tr>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell"
          >
            Cif
          </th>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell"
          >
            Nombre
          </th>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 "
          >
            Asuntos
          </th>
          
         
          <th
          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
          >
            Usuarios

          </th>
          <th
          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
          >
            Asesores

          </th>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Carpeta
          </th>
        </tr>
      </thead>
      <tbody>
        <tr  v-for="user in this.filteredItems()"  :key="user.id">
          <td>
            <div class="d-flex px-2 py-1">
              {{ user.cif }}
            </div>
          </td>
          <td>
            <div class="d-flex px-2 py-1">
              <router-link
                :to="{
                  path: '/company/'+user.id
                }"
                >
                {{user.name}}
                  </router-link>
            </div>
          </td>
          <td>
            <div class="d-flex px-2 py-1">
              <vsud-badge class="m-1" color="success" variant="gradient" size="m">
                {{user.tickets.length}}
              </vsud-badge>
            </div>
          </td>
         
          <td>
            <div class="d-flex px-2 py-1">
              <ul>
                <li v-for="client in user.users" :key="client.id">
                  <router-link
                    :to="{
                      path: '/user/'+client.id
                    }"
                    >
                    <span>{{client.name}}</span>
                  </router-link>
                </li>
              </ul>
               
            </div>
          </td>

          <td>
            <div class="d-flex px-2 py-1">
              <ul>
                <li v-for="asesor in user.asesores" :key="asesor.id">
                  <router-link
                    :to="{
                      path: '/user/'+asesor.id
                    }"
                    >
                    <span>{{asesor.name}} - <div v-if="asesor.department">{{asesor.department.name}}</div></span>
                  </router-link>
                </li>
              </ul>
               
            </div>
          </td>

          <td>
            <router-link
              :to="{
                path: '/documentacion/'+user.folder_id
              }"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder-fill" viewBox="0 0 16 16">
                <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
              </svg>
              Documentación
            </router-link>
          </td>
          

         


          


        </tr>





      </tbody>
    </table>

  </div>
</template>

<script>
import VsudBadge from "@/components/VsudBadge.vue";
import Cargador from "@/components/Cargador.vue";


export default {
  name: "tickets-table",
  data() {
    return {
      loadingUsersAll: true,
      firstLoading:false,
      loading:true,
      users: [],
      error: false,
      openFilter: true,
      services: [
       {
        label:"Fiscal",
        value: "lSerFisc"
       },
       {
        label:"Laboral",
        value: "lSerLabo"
       },
       {
        label:"Consultoría",
        value: "lSerCons"
       },
       {
        label:"Contable",
        value: "lSerCont"
       },
       {
        label:"Jurídicos",
        value: "lSerJuri"
       },
       {
        label:"Gestión",
        value: "lSerGest"
       },
       {
        label:"Renta",
        value: "lClienteRenta"
       },
      ],
      form : {
        allUsers: false,
        search: "",
        services: []
      }
    };
  },
  props : {
    showFilters: {
      type: Boolean,
      default:false
    }
  },
  components: {
    VsudBadge,
    Cargador
    // VsudPagination,
    // VsudPaginationItem
  },
  created() {
    this.getUsers()

  },
  methods: {
    getUsers(){
      
      
      // this.loading=true;
      this.loadingUsersAll = true
      
      this.$axios.post(this.$store.state.baseUrl+'/api/clients', this.form)
        .then(response => {
          if(response.data.rc == 1) {
            this.users = response.data.data
            this.loadingUsersAll = false
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(
          this.loading = false
        )
    },
    filteredItems() {
      var companies = this.users.filter(user => {
         return user.name.toLowerCase().indexOf(this.form.search.toLowerCase()) > -1 || user.cif.toLowerCase().indexOf(this.form.search.toLowerCase()) > -1
      })
      if(this.form.services.length > 0) {
        companies = companies.filter(company => {
          var r = false;
          this.form.services.forEach(service => {
            console.log(company.info[service])
            if(company.info[service])
              r = true
          });
          return r;
        })
      }
        
      
        
      return companies;
    },


    


  }
};





















</script>
