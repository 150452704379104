ç<template>
  <router-link
  class="AvatarAsesor"
  :to="{
    path: '/company/'+company.id
  }"
  >
    <div class="d-flex">

      <div class="avatar asesor text-center" :class="'avatar-'+size">
        <div  class="rounded-circle imageAvatar" >
          <img
            :src="url"
            :alt="alt"
          />
        </div>

        <h6 v-if="span !== false"><span class="badge bg-secondary">{{this.span}}</span></h6>

        <div class="name-asesor text-center mt-1">
          <span class=" text-xs"><strong>{{company.name}}</strong></span>
          <br>
          <span class="text-xs">Empresa</span>

        </div>
      </div>

      <div class="dropdown opcionesAsesor">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
        <div class="buttonOpcionesAsesor text-cennter">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#8493ab" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
          </svg>
        </div>

        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li @click="SolChat()"><a class="dropdown-item">Chat</a></li>
          <li><a class="dropdown-item" v-if="company.phone !== null" :href="'tel:+34'+company.phone">Llamar</a></li>
          <li>
            <router-link
            class="dropdown-item"
            :to="{
              name: 'Solicitar Reunión',

            }"
            >Concertar Reunión
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: 'Empresa',
                params: {
                  id: company.id
                }
              }"
              class="dropdown-item"
              href="#">Perfil
            </router-link>
          </li>
        </ul>
      </div>
    </div>

  </router-link>

</template>

<script>

export default {
  name: "VsudAvatarCompany",
  data() {
    return {
      url: this.$store.state.baseUrl+"/images/profiles/default.png"
    }
  },
  props: {
    img: {
      type: String,
      default: "/img/team-4.897617b8.jpg"
    },
    alt: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: null,
    },
    shadow: {
      type: String,
      default: null,
    },
    borderRadius: {
      type: String,
      default: null,
    },
    span: {
      default:false
    },
    circular: Boolean,
    company: null
  },
  components: {

  },
  created() {
    if(this.company.profile_image == null)
      this.url = this.$store.state.baseUrl+"/images/profiles/default.png"
    else
      this.url = this.company.profile_image.url+'/100.'+this.company.profile_image.format
  },
  methods: {
    // SolChat(){
    //   var self = this;
    //   
    //   
    //   this.$axios.post(this.$store.state.baseUrl+'/api/chat/company/'+this.info.id, {
    //     device_token: self.$store.state.device_token
    //   })
    //     .then(response => {
    //       if(response.data.rc == 1) {

    //         window.location.href = '/chats/'+response.data.data.id;

    //       }
    //     })
    //     .catch(function () {
    //         self.error = true
    //     })
    //     .catch(function () {
    //         self.loading = false
    //     })
    // },
    // getSize: (size) => (size ? `avatar-${size}` : null),
    // getClasses: (shadow, circular, borderRadius) => {
    //   let shadowValue, circularValue, borderRadiusValue;
    //
    //   if (shadow) {
    //     shadowValue = shadow === "regular" ? "shadow" : `shadow-${shadow}`;
    //   } else {
    //     shadowValue = null;
    //   }
    //
    //   circularValue = circular ? "rounded-circle" : null;
    //
    //   borderRadiusValue = borderRadius ? `border-radius-${borderRadius}` : null;
    //
    //   return `${shadowValue} ${circularValue} ${borderRadiusValue}`;
    // },
  },
};
</script>
