/**
=========================================================
* Vue Soft UI Dashboard - v2.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/



import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import axios from 'axios';




const appInstance = createApp(App);


appInstance.use(store);

appInstance.use(router);
appInstance.use(SoftUIDashboard);



// Configura Axios en la instancia de Vue con el header 
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(config => {
  // Agrega el campo del store a la solicitud
  config.headers['Authorization'] = `Bearer ${store.state.token}`;
  return config;
});

appInstance.config.globalProperties.$axios = axiosInstance;

appInstance.mount("#app");




// navigator.serviceWorker.addEventListener('message', (event) => {
//   store.commit('messageFromFirebase', event);
//   console.log('Mensaje recibido mientras la aplicación está en primer plano:', event.data);
//   // Aquí puedes manejar la notificación según tus necesidades
// });

// // Escuchar mensajes del Service Worker
// navigator.serviceWorker.addEventListener('message', (event) => {
//   const { type, payload } = event.data;

//   if (type === 'backgroundMessage') {
//     // Acceder al store y realizar la mutación
//     store.commit('messageFromFirebase', payload);
//   }
// });

// // Registrar el Service Worker
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registrado con éxito:', registration);
//     })
//     .catch((error) => {
//       console.error('Error al registrar el Service Worker:', error);
//     });
// }