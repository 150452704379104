
<template>
  <div>
    <Cargador v-if="loading"/>
    <div v-else class="form-group">
      <h5>{{this.title}}</h5>
      <!-- <input type="search" @change="getUsers()" placeholder="Buscar por nombre Usuario" v-model="form.search" class="form-control" > -->
      <!-- <br> -->
      <div v-if="!this.onlyClients" class="">
        <!-- <label>Asesores</label>

        <select :required="this.requiredAsesores"  @change="handleChangeUsers()" v-model="form.users_ids" class="form-select" size="6" multiple>
          <option  v-for="user in users.asesor"  :key="user.id" :value="user.id">{{user.name}} - {{user.department.name}}</option>
        </select> -->
        <div class="form-group">
          <label>Asesores</label>
          <div v-for="user in this.$store.state.general.asesores"  :key="user.id">
            <div v-if="this.$store.state.auth.id !== user.id"  class="form-check">
            <input   v-model="form.users_ids" @change="handleChangeUsers()" class="form-check-input" type="checkbox" :value="user.id" :id="'check'+user.id">
            <label  class="form-check-label" :for="'check'+user.id">
              {{user.name}} 
            </label>
          </div>
          </div>
          
        </div>


      </div>

      <!-- Usuarios_: {{this.form.users_ids}}
      Cliente: {{this.form.to_id}} -->
      <div v-if="this.$store.state.auth.rol !== 'client'" class="mt-1">
        <label>Empresa:  </label>
        <vSelect v-if="this.to_id == undefined" :required="this.requiredClient" :options="options" v-model="form.to_id"  :reduce="options => options.value" :change="handleChangeUserTo()" >
        </vSelect>
        <div v-else>
         
          {{this.getCompanyName(this.to_id)}}
        </div>
      </div>

    </div>
    <div v-if="!loading && error" class="alert">
      <div class="alert alert-danger">
        Parece que hay algún error
      </div>
    </div>



  </div>
</template>

<script>
import Cargador from "@/components/Cargador.vue";
// import ProjectsTable from "./components/ProjectsTable";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";


export default {
  name: "searchUsers",
  emits: [
    "update:users_ids",
    "update:to_id"
  ],
  components: {
    Cargador,
    vSelect
  },
  data() {
    return {
      loading: true,
      error: false,
      userInBegining:false,
      users: {

      },
      options: [],
      form: {
        search: "",
        users_ids: [],
        to_id: null
      }
    }
  },
  props:['users_ids','to_id','title','onlyClients','requiredClient','requiredAsesores'],
  mounted(){
    this.form.users_ids = this.users_ids
    

    this.getUsers()
  },
  methods : {
    handleChangeUsers(){
      this.$emit('update:users_ids', this.form.users_ids)
    },
    handleChangeUserTo(){

      // alert('jey')
      if(this.form.to_id !== this.to_id) {
        this.$emit('update:to_id', this.form.to_id)

      }
    },
    getUsers() {
      this.loading = true
      
      
      var self = this
      this.$axios.post(this.$store.state.baseUrl+'/api/search/companies', this.form)
        .then(response => {
          if(response.data.rc == 1) {
            self.users = response.data.data
            self.loadOptions(response.data.data);
          } else {
            this.error = true
          }
        })
        .catch(function (err) {
            self.error = true
            console.error(err)

        })
        .finally( function() {
          self.loading = false
          if(self.$route.query.to_id !== undefined)
            self.form.to_id = self.$route.query.to_id
        })
    },
    // carga las opciones para el selector de usuarios
    loadOptions(users) {
      console.log(users)
      users.forEach((user) => {
        this.options.push({
          label : this.ifMoreNames(user),
          key: user.id,
          value: user.id,
          meta: {
            email: user.email
          }
        })
      });

    },
    // si tiene nombre comercial se devolverá el nombre y su segundo nombre
    ifMoreNames(company) {
      if(company.representative_name){
        return company.name+' - '+company.representative_name
      }
      return company.name
    }, 
    getCompanyName(idIn) {
      for (let index = 0; index < this.users.length; index++) {
        if(this.users[index].id == idIn)
          return this.users[index].name
        
      }
    }
  }
};
</script>
