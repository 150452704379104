<template>
  <div class="">

    <Cargador v-if="loading"/>
    <div v-else class="container">
      <div v-if="error" class="alert alert-warning mt-3">
        Parece que hay algun error o no tienes permiso
      </div>
      
      
      
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/fondo_empresas.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class=""></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative overflow-hidden">
              <img
                :src="this.url"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h3 class="mb-1">{{info.name}}</h3>
              <p v-if="info.type == 'autonomous'" class="mb-0 text-sm font-weight-bold">Autónomo</p>
              <p v-else class="mb-0 text-sm font-weight-bold">Empresa</p>


            </div>
          </div>
          <div
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
          >
            <div v-if="this.$store.state.auth.rol == 'asesor' && this.info.rol !== 'admin'" class="nav-wrapper position-relative end-0">
              <ul
                class="p-1 bg-transparent nav d-flex"
                role="tablist"
              >
                <li class="nav-item m-1">
                  <router-link
                  :to="{
                    name:'Abrir Asunto',
                    query: {
                      to_id: this.info.id
                    },
                    props: true
                  }"
                  >
                    <button type="button" class="btn btn-secondary" name="button">Abrir Asunto</button>
                  </router-link>
                </li>
                <li class="nav-item m-1">
                  <button v-if="this.$store.state.auth.rol == 'asesor'" @click="SolChat()" type="button" class="btn btn-secondary" name="button">Chat</button>
                </li>
                <!-- <li @click="SolChat()" class="nav-item">
                  <button type="button" class="btn btn-secondary" name="button">Chat</button>
                </li> -->

                <li class="nav-item m-1">
                  <router-link
                  :to="{
                       name: 'Solicitar Reunión',
                        query: {
                           to_id: info.id
                         },
                         props: true
                       }"
                  >
                    <button type="button" class="btn btn-secondary" name="button">Solicitar Reunión</button>
                  </router-link>
                </li>


              </ul>
            </div>
          </div>
        </div>
      </div>



      <!--  -->
      <div class="row mt-4">
        <div class="col-md-8">
          <Info :info="this.info.info"/>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h4>Usuarios: </h4>
              <ul>
                <li v-for="(user) in this.info.users" :key="user.id" >
                  <router-link
                :to="{
                  path: '/user/'+user.id
                }"
                >
                {{user.name}}
                  </router-link>
                
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Asuntos -->
      <div class="row mt-4" id="tickets">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h4>Asuntos Abiertos</h4>
              <Tickets :company_id="this.info.id"/>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>


</template>
<style>
p {
  margin-bottom: 4px !important;
}
</style>
<script>
import Cargador from "@/components/Cargador.vue";
import Info from "@/views/components/User/Info.vue";
import Tickets from "@/views/components/Tickets/table.vue";




export default {
  name: "ProfileOverview",
  components: {
    Cargador,
    Info,
    Tickets
  },
  data() {
    return {
      loading:true,
      error:false,
      url:this.$store.state.baseUrl+"/images/profiles/default.png",
      info: {},
      selection: null
    };
  },
  beforeCreate(){
    // this.$store.state.ownKey++;
  },
  mounted() {
    this.getInfo();

  },
  methods: {
    getInfo() {
      

      
      this.$axios.get(this.$store.state.baseUrl+'/api/company/'+this.$route.params.id, null)
        .then(response => {
          if(response.data.rc == 1) {
            this.info = response.data.data
            if(this.info.profile_image == null) {
              this.url = this.$store.state.baseUrl+"/images/profiles/default.png"
            } else {
              this.url = this.info.profile_image.url+'/100.'+this.info.profile_image.format
            }
            // el primer selection

          } else {
            this.error = true
          }
        })
        .catch(error => {
          this.error = true
          console.log(error)
        })
        .finally(() => {
          this.loading = false;

        })

    },
    changeApartado(apartado) {
      this.selection = apartado

    },
    SolChat(){
      var self = this;
      
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/chat/solicitar', {
        company_id: this.info.id
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.$router.push({path: '/chats/'+response.data.data.id })



          }
        })
        .catch(function () {
            self.error = true
        })
        .catch(function () {
            self.loading = false
        })
    },
    logout() {
      this.loading = true;

      var self = this;
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/logout', {
        device_token: self.$store.state.device_token
      })
        .then(response => {
          if(response.data.rc == 1) {

            window.location.href = '/';

          }
        })
        .catch(function () {
            self.error = true
        })
        .catch(function () {
            self.loading = false
        })

    }
    // requestMeet(){
    //   this.$router.push({
    //     name: 'Solicitar Reunión',
    //       params: {
    //         ticket_id: this.ticket.id
    //       },
    //       props: true
    //     })
    // },
  }

};
</script>
