<template>
  <small :class="'expiration text-xs p-2 '+this.getClassExpiration()">
    {{date}}
    <span class="text-xs w-100">{{ daysDifference }} días restantes</span>
  </small>
</template>

<script>

// import VsudPagination from "@/components/VsudPagination.vue"
// import VsudPaginationItem from "@/components/VsudPaginationItem.vue"

export default {
  name: "expiration",
  data() {
    return {
      expiration_date: this.date,
      daysDifference: 0
    };
  },  
  props: {
    date: {
      required: true
    }
  },
  mounted(){
    // pass to format date to work with that
    const [day, month, year] = this.expiration_date.split('/');
    this.expiration_date = new Date(`${year}-${month}-${day}`);
    // check the days remaining
    this.checkDaysRemaining();
  },

  methods: {
    // depending the days remaining get the class for color
    checkDaysRemaining() {
      const currentDate = new Date();
      const targetDate = this.expiration_date;
      
      // calculate in ms
      const timeDifference = targetDate.getTime() - currentDate.getTime();

      // transform to days
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      this.daysDifference = daysDifference;
    },
    getClassExpiration(){
      if(this.daysDifference>5)
        return "expiration-far"
      if(this.daysDifference<=5 && this.daysDifference>=3)
        return "expiration-near"
      if(this.daysDifference<3 && this.daysDifference>=0)
        return "expiration-alert"
      return "expiration-death"

    }
  }
};
</script>
