<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-3">
        <a target="_blank" :href="'https://getquipu.com/es/entrar?user[email]='+this.$store.state.auth.email">

          <div class="app">
              <img src="" alt="">
              <!-- <span>Quipu</span> -->
          </div>
        </a>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "tables",
  components: {

  },
};
</script>
