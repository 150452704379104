<template>
  <div class="optionMessage" :ref="'messageOptions' + message.id">
    <svg
      :ref="'optionToOpen' + message.id"
      @click="toggleOpen"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 512 512"
    >
    <path d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"/>
    </svg>
    <div v-if="open" class="headerOptions">
      <ul>
        <li v-if="message.user_id == this.$store.state.auth.id" @click="this.deleteMessage()">Eliminar Mensaje</li>
        <li v-if="$store.state.auth.rol == 'asesor'" @click="toggleOpenList">
          Ver leídos
        </li>
        <readbyList
          :class="{ movedTisght: message.user_id !== $store.state.auth.id }"
          v-if="openList && $store.state.auth.rol == 'asesor'"
          :message="message"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import readbyList from "@/views/components/Chats/messages/readByList.vue";

export default {
  data() {
    return {
      open: false,
      openList: false,
      loading :false
    };
  },
  props: ["message"],
  components: {
    readbyList,
  },

  beforeMount() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    deleteMessage() {
      this.loading = true
      var self = this
  
      this.$axios.post(this.$store.state.baseUrl+'/api/message/'+this.message.id+'/delete')
        // .then(response => {
        //   if(response.data.rc == 1) {
        //     console.log(response)
        //   }
        // })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
            self.loading = false
            
        })
    },
    toggleOpen() {
      this.open = !this.open;
    },
    toggleOpenList(event) {
      event.stopPropagation(); // Evita que el clic se propague al documento y cierre inmediatamente la lista
      this.openList = !this.openList;
    },
    handleClickOutside(event) {
      if (this.open) {
        const clickedElement = event.target;
        const messageOptions = this.$refs["messageOptions" + this.message.id];

        if (messageOptions && !messageOptions.contains(clickedElement)) {
          this.open = false;
          this.openList = false
        }
      }
    },
  },
};
</script>
