<template>
  <div class="py-4 container-fluid mt-3">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">
            <h5 class="font-weight-bolder">Calendario</h5>
            <div class="d-flex">
              <router-link
              v-if="this.$store.state.auth.rol == 'asesor'"
              :to="{
                name: 'Solicitar Reunión',

              }"
              >
               <button type="button" class="btn btn-primary" name="button">Crear Reunión</button>
              </router-link>
              <router-link
              v-if="this.$store.state.auth.rol == 'asesor'"
              :to="{
                name: 'Reuniones',

              }"
              >
               <button type="button" class="btn btn-secondary ms-2" name="button">Vista Lista <i class="bi bi-list"></i></button>
              </router-link>
            </div>
          </div>
          
          <div class="card-body ">
            <!-- Los filtros están inacabados -->
            <!-- {{ nextMeets }} -->
            <VueCal 
            v-if="!loading"
            locale="es"
            :time-from="8 * 60"
            :time-to="20 * 60"
            :time-step="30"
            :showWeekNumbers="false"
            :events="nextMeets"
            hide-weekends
            
            :on-event-click="this.onEventClick"

            />
            <Cargador v-else/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import VsudPagination from "@/components/VsudPagination.vue"
import VueCal from 'vue-cal'

import Cargador from "@/components/Cargador.vue";

import 'vue-cal/dist/vuecal.css'

export default {
  name: "meetsCalendar",
  data() {
    return {
      loading: true,
      response: null,
      nextMeets: [],
    };
  },
  components: {
    VueCal,
    Cargador,
    // VsudPaginationItem
  },
  mounted(){
    this.getData()
  },
  methods: {
    adaptEvent(event){
      var start = this.fechaObjeto(event.meet_on+ ' '+event.at)
      var end = this.sumOneHour(start)
      return {
        id: event.id,
        start: start,
        end: end, 
        title: event.title,
        content: '<small>'+event.description+'</small>',
        class: 'eventCalendar'
      }
    },
    sumOneHour(fechaCadena) {
      const fechaOriginal = new Date(fechaCadena);
      const nuevaFecha = new Date(fechaOriginal.getTime() + 60 * 60 * 1000); // Sumar 1 hora en milisegundos
      
      const year = nuevaFecha.getFullYear();
      const month = String(nuevaFecha.getMonth() + 1).padStart(2, "0");
      const day = String(nuevaFecha.getDate()).padStart(2, "0");
      const hour = String(nuevaFecha.getHours()).padStart(2, "0");
      const minute = String(nuevaFecha.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} ${hour}:${minute}`;
    },
    fechaObjeto(fechaCadena) {
      const partes = fechaCadena.split(" ");
      const fechaPartes = partes[0].split("/");
      const horaPartes = partes[1].split(":");

      const year = fechaPartes[2];
      const month = fechaPartes[1];
      const day = fechaPartes[0];
      const hour = horaPartes[0];
      const minute = horaPartes[1];

      return `${year}-${month}-${day} ${hour}:${minute}`;

    },
    onEventClick (event, e) {
      // console.log('event',event)
      e.stopPropagation()
      this.$router.push({path: '/meets/'+event.id })

    },
    getData() {

      this.loading = true;
      

      
      var self = this
      this.$axios.post(this.$store.state.baseUrl+'/api/meets', {
        company_id: this.$store.state.currentCompany_id
      })
      .then(response => {
        if(response.data.rc == 1) {
          this.response = response.data.data;
          this.nextMeets = this.response.map(event => this.adaptEvent(event));
        } else {
          this.error = true
        }
      })
      .catch(function () {
          self.error = true
      })
      .finally(function () {
        self.loading = false

      })
    }
  }
};
</script>
