<template>
  <router-link 
        :to="{
          name: 'User',
          params: {
            id: user.id
          }
        }"
       class="participant">
    <div class="participantImage">
      <img :src="url" alt="">
    </div>
    <div class="participantName">
      <span v-if="user.id==this.$store.state.auth.id">
          Yo 
          <!-- <small>
            ({{ user.name }})
        </small> -->
      </span>
      <span v-else>{{user.name}} </span>
    </div>
  </router-link>

</template>
  
  <script>

  
  export default {
    name: "participant",
    props: ['user'],
    data() {
      return {
        url: null,

      };
    },
    components: {
    },
    created() {
      if(this.user.profile_image)
        this.url = this.user.profile_image.url+'/100.'+this.user.profile_image.format
      else
        this.url = "https://ui-avatars.com/api/?name="+this.user.name+"&background=eaddff&color=21005d&size=75"


    },


  
  };
  </script>
  
  

    
  