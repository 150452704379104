<template>
<div class="Editor-t">

  <div  v-if="openUploader" class="floatingPage">
    <div class="d-flex pointer">
      <svg @click="openUploader=false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x-circle-fill closeButton" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/></svg>
    </div>
    <UploadDocument :nonredirect="true" @uploaded="this.openUploader = false" :chat="this.$store.state.chats.current"  />
  </div>

  <small v-if="this.form.message.length !== 0" class="helperEnnter"><b>Ctrol + Intro</b> para añadir un salto de línea </small>

    <div class="editorNore">
      <div @click="openOption = !openOption" class="moreButton">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
      </div>

      <div v-if="openOption" class="optionsChatWritter pointer">
        <div @click="this.openUploader = true; openOption = false">
          Adjuntar Documento
        </div>

      </div>

    </div>
    <div id="writter" 
    :class="{ 
      senderPublic: this.form.public ,
      'writterw-100': !this.SwitchHasToBeVisible(),

      }">
      <VueEditor :key="this.keyEditorMessage" @ready="onReady" :placeholder="'Escribir mensaje'" ref="editorRef" @keydown="handleKeyDown"  :editorOptions="editorSettings" id="writterMessage"  v-model="this.form.message"/>
      <div id="sendButton" @click="this.sendMessage()">
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"/></svg>      </div>
    </div>
    <div class="visibilityClient">
      <soft-switch
        v-if="this.$store.state.auth.rol !== 'client' && this.SwitchHasToBeVisible()"
        id="flexSwitchCheckDefault"
        class="ps-0 ms-auto"
        name="visible"
        label-class="mb-0 text-body ms-3  w-80"
        :value="this.form.public"
        @valor="form.public=$event"
        >Visible a Cliente
      </soft-switch>
    </div>
</div>

</template>
  
  <script>
  import UploadDocument from "@/views/uploadDocument.vue";
  import SoftSwitch from "@/components/SoftSwitch.vue";
  import _  from 'lodash';
  import { VueEditor, Quill } from 'vue3-editor';
  import Emoji from 'quill-emoji/dist/quill-emoji';
  Quill.register({
  'formats/emoji': Emoji.EmojiBlot,
  'modules/short_name_emoji': Emoji.ShortNameEmoji,
  'modules/toolbar_emoji': Emoji.ToolbarEmoji,
  'modules/textarea_emoji': Emoji.TextAreaEmoji}, true);
  export default {
    name: "writter",
    props: ['chat'],
    data() {
      return {
        openUploader: false,
        url: null,
        openOption: false,
        keyEditorMessage: 1,
        showHelp: false,
        form: {
          message:"",
          public: false

        },
        editorSettings: {
                modules: {
                
                toolbar: {
                    container: [
                      ['emoji'],

                        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        // [{'size': ['small', false, 'large']}],
                        ['bold', 'italic', 'underline', 'strike'],
                        // ['blockquote', 'code-block'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        // [{ 'script': 'sub' }, { 'script': 'super' }],
                        // [{ 'indent': '-1' }, { 'indent': '+1' }],
                        // [{ 'direction': 'rtl' }],
                        // [{ 'color': [] }, { 'background': [] }],
                        [{ 'color': [] }],

                        // [{ 'font': [] }],
                        [{ 'align': [] }],
                        // ['clean'],
                        ['link'],

                        // ['link', 'image', 'video'],
                    ],
                    handlers: {
                      'emoji': function () {
                        const range = this.quill.getSelection();
                        this.quill.insertText(range.index, '😃');
                        this.quill.setSelection(range.index + 1);
                      }
                  },
                },
                toolbar_emoji: true,
                short_name_emoji: true,
                textarea_emoji:true,
            },
        },


        };
    },
    components: {
      VueEditor,
      SoftSwitch,
      UploadDocument
    },
    created() {
      // if all the users, except the logged one are asesors then public by default
      // Verifica si hay algún usuario con rol "client" exceptuando al usuario logeado
      const hasClientUser = _.some(this.$store.state.chats.current.users, (user) => user.rol === 'client' && user.id !== this.$store.state.auth.id);

      if (hasClientUser && !this.$store.state.chats.current.ticket) {
        this.form.public = true
      } 
    },

    methods: {
      onReady(editor) {
        editor.focus();
      },
      SwitchHasToBeVisible(){
        return _.some(this.$store.state.chats.current.users, (user) => user.rol === 'client');
      },
      limpiarTextoHTML(textoHTML) {
          // Eliminar etiquetas HTML
          let textoSinHTML = textoHTML.replace(/<[^>]*>/g, '');

          // Reemplazar saltos de línea con espacios
          let textoSinSaltosDeLinea = textoSinHTML.replace(/\n/g, ' ');

          // Eliminar espacios duplicados
          let textoFinal = textoSinSaltosDeLinea.replace(/\s+/g, ' ').trim();

          return textoFinal;
      },
      
      sendMessage() {
        var text = this.limpiarTextoHTML(this.form.message)
        if(text.length == 0)
          return
        var publicvi = false
        if(this.form.public)
          publicvi = true;
        if(!publicvi) {
          if(!_.some(this.$store.state.chats.current.users, (user) => user.rol === 'client'))
          publicvi = true;
        }
        if(this.$store.state.auth.rol == 'client')
          publicvi = true
        const uniqueId = Date.now();
        const ownMessage = {
          id: uniqueId,
          message: this.form.message,
          user: this.$store.state.auth,
          user_id: this.$store.state.auth.id,
          from_system: false,
          public: publicvi,
          readed_by: '['+this.$store.state.auth.id+']',
          ticket: null,
          ticket_id: null,
          company: null,
          company_id: null,
          created_at	:	Date.now(),
          updated_at	:	Date.now(),
          chat_id	:	 this.$store.state.chats.current.id,
          document_id	:	null,
          request_form_id	:	null,
          deleted_at	:	null,
          front_id	:	uniqueId,
          sending: true,

        }
        this.$store.state.chats.current.last_messages.unshift(ownMessage)
        
        this.form.message = '';
        this.reloadEditor()
        this.showHelp = false
        this.$store.state.scrollDown('containMessages')

        
      },
      focusEditor() {
        // Obtén la referencia al editor usando this.$refs
        const editorRef = this.$refs.editorRef;

        // Verifica si la referencia existe y tiene el método .focus()
        if (editorRef && editorRef.quill && editorRef.quill.focus) {
          // Hacer foco en el editor
          editorRef.quill.focus();
        } 
      },
      handleKeyDown(event) {
        if (event.key === 'Enter') {
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
            const editor = this.$refs.editorRef.quill; // Obtén la instancia de Quill
            const cursorPosition = editor.getSelection().index; // Posición del cursor

            // Inserta un salto de línea en la posición del cursor
            editor.insertText(cursorPosition, '\n');
          } else {
            // En caso contrario, envía el mensaje
            this.sendMessage();
          }
        }
      },
      reloadEditor() {
        this.keyEditorMessage++;
        this.focusEditor();
      }
    }


  
  };
  </script>
  
  

    
  