import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Documentacion from "@/views/Documentacion.vue";
import Tickets from "@/views/Tickets.vue";
import TicketView from "@/views/TicketView.vue";
import RequestFormsList from "@/views/RequestFormsList.vue";
import RequestFormView from "@/views/RequestFormView.vue";
import RequestMeet from "@/views/components/Meets/RequestMeet.vue";
import SeeMeet from "@/views/components/Meets/SeeMeet.vue";

import NotificationsList from "@/views/NotificationsList.vue";
import Archivo from "@/views/Archivo.vue";
import Comunicacion from "@/views/Comunicacion.vue";
import Despacho from "@/views/Despacho.vue";
import Chats from "@/views/Chats.vue";
import currentChat from "@/views/components/Chats/current.vue"

import Meets from "@/views/Meets.vue";
import MeetsCalendar from "@/views/MeetsCalendar.vue";

import ThirdApps from "@/views/ThirdApps.vue";
import Clientes from "@/views/Clientes.vue";
import CreateTicketView from "@/views/CreateTicket.vue";
import UploadDocument from "@/views/uploadDocument.vue";
import RequerirFormulario from "@/views/requerirFormulario.vue";
import Noticia from "@/views/Noticia.vue";


//
import Billing from "@/views/Billing.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Company from "@/views/Company.vue";

import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import multiguard from 'vue-router-multiguard';
import cookie from 'cookiejs';

const hasToken = function(to, from, next) {
  var c = cookie.get('authToken')
  if(c == false || c == undefined)
    return router.push({ name: 'Sign In' });
  // checkemamos la cookie
  return next();
}



const routes = [
  {
    path: "/",
    beforeEnter: multiguard([hasToken]),
    name: "Inicio",
    component: Dashboard,
  },
  {
    path: "/dashboard",
    beforeEnter: multiguard([hasToken]),
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/news/:id",
    beforeEnter: multiguard([hasToken]),
    name: "Notícias",
    component: Noticia,
  },
  {
    path: "/comunicacion",
    beforeEnter: multiguard([hasToken]),
    name: "Comunicacion",
    component: Comunicacion,
  },
  {
    path: "/team",
    beforeEnter: multiguard([hasToken]),
    name: "Despacho",
    component: Despacho,
  },
  {
    path: "/documentacion/:folder_id?",
    beforeEnter: multiguard([hasToken]),
    name: "Documentacion",
    component: Documentacion,
    props: true

  },
  {
    path: "/upload",
    beforeEnter: multiguard([hasToken]),
    name: "Subir Documento",
    component: UploadDocument,
  },
  {
    path: "/documentacion/archivo/:id",
    beforeEnter: multiguard([hasToken]),
    name: "Archivo",
    component: Archivo,
  },
  {
    path: "/tickets",
    beforeEnter: multiguard([hasToken]),
    name: "Asuntos",
    component: Tickets,
  },
  {
    path: "/tickets/:id",
    beforeEnter: multiguard([hasToken]),
    name: "Ticket",
    component: TicketView,
    props:true
  },
  {
    path: "/chats/",
    beforeEnter: multiguard([hasToken]),
    name: "Chats",
    component: Chats,
    children: [
      {
        path: ':id', component: currentChat,
        name: "Chat"
      },
    ]
  },
  {
    path: "/request_forms",
    beforeEnter: multiguard([hasToken]),
    name: "Solicitud de Formularios",
    component: RequestFormsList,
  },
  {
    path: "/request_forms/new",
    beforeEnter: multiguard([hasToken]),
    name: "Requerir Formulario",
    component: RequerirFormulario,
  },
  {
    path: "/request_forms/:id",
    beforeEnter: multiguard([hasToken]),
    name: "Solicitud de Formulario",
    component: RequestFormView,
  },
  {
    path: "/meets",
    beforeEnter: multiguard([hasToken]),
    name: "Reuniones",
    component: Meets,
  },
  {
    path: "/meets/calendar",
    beforeEnter: multiguard([hasToken]),
    name: "Calendario Reuniones",
    component: MeetsCalendar,
  },
  {
    path: "/meets/request",
    beforeEnter: multiguard([hasToken]),
    name: "Solicitar Reunión",
    component: RequestMeet,
    props: {
      to_id: null,
      ticket_id: null
    }
  },
  {
    path: "/meets/:id",
    beforeEnter: multiguard([hasToken]),
    name: "Reunión",
    component: SeeMeet,
  },
  {
    path: "/apps",
    beforeEnter: multiguard([hasToken]),
    name: "Apps",
    component: ThirdApps,
  },
  {
    path: "/clients",
    beforeEnter: multiguard([hasToken]),
    name: "Empresas",
    component: Clientes,
  },
  {
    path: "/billing",
    beforeEnter: multiguard([hasToken]),
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    beforeEnter: multiguard([hasToken]),
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/user/:id",
    beforeEnter: multiguard([hasToken]),
    name: "User",
    component: Profile,
    props:true
    
  },
  {
    path: "/company/:id",
    beforeEnter: multiguard([hasToken]),
    name: "Empresa",
    component: Company,
    props:true
    
  },
  {
    path: "/ticket/create",
    beforeEnter: multiguard([hasToken]),
    name: "Abrir Asunto",
    component: CreateTicketView,
    props: {
      to_id: null,
      name_to: null
    }
  },

  {
    path: "/notificaciones",
    beforeEnter: multiguard([hasToken]),
    name: "Notificaciones",
    component: NotificationsList,
    props:true
  },

  {
    path: "/rtl-page",
    beforeEnter: multiguard([hasToken]),
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
