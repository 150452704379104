<template>
  <div class="d-flex justify-content-center">
    <img :width="width?? 100 + 'px'" :src="this.getUrl()" alt="">
  </div>
</template>

<script>
export default {
  name: "Cargador",
  props: {
    width: {
      type: Number,
      default: 100,
    },
    style: {
      default: "default"
    }

  },
  methods: {
    getUrl() {
      if(this.style == "default")
        return "/loading.gif"
      if(this.style == "chat")
        return "/loading-list.gif"
      if(this.style == "circle")
        return "/loading_circle.gif"
    }
  }
};
</script>
