<template>
  <div v-if="!closed" class="aviso-full">
    <div class="row m-auto">
      
      <div class="col-md-12 avisos">
        <div class="alert alert-warning w-95 m-auto" style="background: #fef5d6;">
          <div class=" d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#ff0a0a" class="bi bi-app-indicator bellicon m-2" viewBox="0 0 16 16">
              <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
              <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            </svg>
            <p class="mt-2 ms-2">Debes Aceptar Las notificaciones de escritorio para el correcto funcionamiento del aplicativo. Puedes obtener más información sobre como activar las notificaciones de escritorio</p>
            
          </div>
          <div class="text-right">
            <a  target="_blank" href="https://support.google.com/chrome/answer/3220216?hl=es&co=GENIE.Platform%3DDesktop">
              <button class="btn btn-primary">Cómo Activar</button>
            </a>
            <button @click="closed = true" class="btn btn-secondary ms-2">Cerrar</button>
          </div>
        
        </div>
      </div>
    </div>
    
  </div>



</template>

<script>



export default {
  data() {
    return {
      closed: false
    }
  },
  created() {

  },
  components: {

  }


};
</script>

<style >
  .aviso-full {
    width: 100vw;
    height: 100vh;
    background: #8392abb0;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
  }

  .avisos {
    margin-top: 18vh !important;
    max-width: 1100px !important;
    margin: auto;

  }
</style>