<template>
  <div>
    <div v-if="this.isVisible() && !message.from_system" class="messageChatGen " :class="{ fromMe: this.$store.state.auth.id == message.user_id }">
      <participant :user="message.user" />
      <div @click="checkRead()" class="bodyMessage">
        
        <div class="messageChat "  :class="{ messageReaded: this.$store.state.isReadBy(message,this.$store.state.auth.id) }">
          <div class="f-docu" v-if="message.document_id">
            {{ message.message }}. 
            <router-link 
                :to="{
                    name: 'Archivo',
                    params: {
                      id: message.document_id
                    }
                  }">
              Ver documento
              </router-link>
          </div>
          <div v-else-if="message.deleted_at" class="deletedMessage">
            Mensaje eliminado
          </div>
          <p v-else class="messageContent" v-html="message.message"></p>
          
          <options v-if="!message.deleted_at && (this.$store.state.auth.rol == 'asesor') || message.user_id == this.$store.state.auth.id" :message="message"/>
          <div class="infoMessage">

            <div class="containInfoMessage">
              <Cargador class="sennding" v-if="sending" :style="'circle'" :width="30"/>
              
              <div v-if="SwitchHasToBeVisible() && message.public && this.$store.state.auth.rol !== 'client' && message.user.rol !== 'client' && !this.$store.state.chats.current.despacho" class="ifVisible">Visible a cliente</div>
              <small class="">{{this.$store.state.dateFormat(message.created_at)}}</small>
              <readed :message="message"/>
            </div>
            <div v-if="error" class="alert alert-danger">
                Error
              </div>
          </div>
        </div>
      </div>
    </div>
    <fromSystem v-if="message.from_system" :message="message"/>
    
  </div>

</template>
  
  <script>
  import readed from "@/views/components/Chats/messages/readed.vue"
  import fromSystem from "@/views/components/Chats/messages/fromSystem.vue"
  import Cargador from "@/components/Cargador.vue";
  import _ from 'lodash';

  import options from "@/views/components/Chats/messages/options.vue"
  import participant from "@/views/components/Chats/participant.vue"

  export default {
    name: "message",
    data() {
      return {
        sending: false,
        error: false,
        reading: false,
      };
    },
    props: ['message'],
    components: {
      participant,
      readed,
      options,
      fromSystem,
      Cargador
    },
    created() {
      this.sending = this.message.sending?? false
      if(this.sending)
        this.sendMessage()
    },

    methods: {
      isVisible() {
        var visible = true;
        if(this.$store.state.auth.rol == 'client' && !this.message.public)
          visible = false;
        // if(!this.message.from_system && this.$store.state.chats.form.messagesNotReaded == false || (this.$store.state.chats.form.messagesNotReaded && !this.$store.state.isReadBy(this.message,this.$store.state.auth.id) ))
        //   visible = true;
        // if(this.$store.state.auth.rol == 'client' && this.message.visible == false)
        //   visible = false;
        return visible
      },
      checkRead() {
        if(this.message.user_id == this.$store.state.auth.id)
          return;
        if(this.$store.state.isReadBy(this.message,this.$store.state.auth.id))
          return;
        if(this.reading)
          return;

        this.reading = true
        this.$axios.post(this.$store.state.baseUrl+'/api/message/'+this.message.id+'/read')
        .then(response => {
          if(response.data.rc == 1) {
              this.$store.state.chats.current.pending_messages--
              this.$store.state.general.pendingMessages--
          } else
            this.error = true

        })
        .catch(() =>  {
            this.error = true
        })
        .finally(() =>  {
            // this.sending = false
            this.reading = false
        })

      },
      sendMessage() {
        
        this.$axios.post(this.$store.state.baseUrl+'/api/send', 
        {
          message: this.message.message,
          chat_id: this.$route.params.id,
          public: this.message.public,
          front_id: this.message.front_id

        })
        .then(response => {
          if(response.data.rc == 1) {
              // search if present in list 
              var index = _.findIndex(this.$store.state.chats.current.last_messages, { front_id: response.data.data.front_id });
              if (index !== -1) {
                this.$store.state.chats.current.last_messages[index] = response.data.data;
              }
          } else
            this.error = true

        })
        .catch(() =>  {
            this.error = true
        })
        .finally(() =>  {
            this.sending = false
        })
      },
      SwitchHasToBeVisible(){
        return _.some(this.$store.state.chats.current.users, (user) => user.rol === 'client');
      },
    }
  
  };
  </script>
  
  

    
  