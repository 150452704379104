<template>
    <div>
      <div v-if="showFilters" class=" pb-0">
        <div class="container-fluid">
          <div class="row d-flex justify-content-between mb-4">

            <div class="col-md-6 d-flex">
              <div class="filter m-auto w-100">
                <div class="text-left label">Por Título / Empresa</div>
                <div class="input-group m-auto">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Buscar"
                    v-model="form.search"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-3  ">
              <!-- FILTRO -->
              <div class="text-right label">Estado</div>
              <select v-model="selection" class="form-select selector" >
                <option value="all">Todos</option>
                <option value="pending">Pendientes</option>
                <option value="closed">Cerrados</option>
              </select>
            </div>

          </div>

        </div>
      </div>
      <Cargador v-if="loading" />

      <div v-else class="table-responsive p-0 col-12 m-auto">
        <div v-if="filterTickets().length == 0" class="text-center w-100">
              <img width="150" src="not-found.gif" alt="">

              <h6  class="">No se han encontrado Asuntos</h6>
        </div>
        <table v-else class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell"
                v-if="this.max == false"
              >
                Estado
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell"
              >
                Título
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell"
              >
                Creación
              </th>
              <th
              
                class=" ml-3 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell"
              >
                Empresa
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell"
              >
                Fecha Vencimiento
              </th>
             


              <th
              v-if="this.$store.state.auth.rol == 'asesor' && this.max == false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
              >
                Minutos Hoy

              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >

              </th>
              <th
              v-if="this.max == false"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Opciones
              </th>
            </tr>
          </thead>


          



          <tbody >
            
            <tr v-for="(ticket,index) in this.filterTickets()"  :key="ticket.id" >
              
              <td  v-if="this.max == false" class="d-none d-md-table-cell ">
                <div v-if="ticket.status !== 'done'" class="d-flex px-2 py-1">
                  <i class="fa fa-circle me-1 m-1 pending"></i>
                </div>
                <div v-if="ticket.status === 'done'" class="d-flex px-2 py-1">
                  <i class="fa fa-circle me-1 m-1 success"></i>
                </div>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'Ticket',
                    params: {
                      id: ticket.id
                    }
                  }">
                    {{ticket.title}}
                </router-link>
              </td>
              <td>
                <div   class="">
                  <small>
                  {{this.$store.state.dateFormat(ticket.created_at)}}
                </small>
                </div>


              </td>
              <td >
                <!-- Si es cliente -->
                <router-link
                v-if="ticket.company"
                  :to="{
                    name: 'Empresa',
                    params: {
                      id: ticket.company.id
                    }
                  }"
                  class="dropdown-item"
                  >
                  <span  >{{ticket.company.name}} </span>
                </router-link>
                <!-- Si es empresa -->
                
              </td>
              <td>
                <div  v-if="ticket.expiration_on !==null" class="">
                  <Expiration v-if="ticket.status == 'pending'" :date="ticket.expiration_on"/>
                  <span v-else>{{ ticket.expiration_on }}</span>
                </div>
                <div v-else class="">
                    <span> -- </span>
                </div>
              </td>
              



              <td v-if="this.$store.state.auth.rol == 'asesor' && this.max == false">
                <div v-if="ticket.today_timing == null" class="input-group">
                  <input v-on:keyup.enter="addTimming(ticket,index,$event)" placeholder="Num Minutos" type="text" class="form-control"  >
                </div>
                <div v-else class="text-sm">
                  <small>✅ Hoy ya has añadido {{ticket.total_time}} minutos ⏱ 
                    <a @click="ticket.today_timing = null" class="" type="button" name="button">Añadir más</a>
                  </small>
                </div>
              </td>

              <td>
                <!-- <small>Formularios pendientes: {{ticket.num_pending_forms}}</small> -->
              </td>


              <td  v-if="this.max == false">
                <div class="d-flex px-2 py-1">
                  <router-link
                    :to="{
                      name: 'Ticket',
                      params: {
                        id: ticket.id
                      }
                    }">
                  <vsud-badge class="m-1" color="success" variant="gradient" size="m">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
                    </svg>
                  </vsud-badge>

                </router-link>

                <vsud-badge v-if="this.$store.state.auth.rol !== 'client'" @click="deleteTicket(ticket)" class="m-1 pointer" color="danger" variant="gradient" size="m">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                </vsud-badge>

                </div>
              </td>


            </tr>




          </tbody>
        </table>

      </div>
    </div>

</template>

<script>
const humanizeDuration = require("humanize-duration");

import VsudBadge from "@/components/VsudBadge.vue";
import Cargador from "@/components/Cargador.vue";

import Expiration from "@/views/components/Tickets/expiration"


export default {
  name: "tickets-table",
  data() {
    return {
      loading:true,
      tickets: [],
      form: {
        client_id: null,
        onlyPending: true,
        search: ""
      },
      selection: "pending"
    };
  },
  props: {
    showFilters:{
      type: Boolean,
      default:false
    },
    max: {
      default: false
    },
    company_id: {
      default: false
    }
  },
  components: {
    VsudBadge,
    Cargador,
    Expiration
    // VsudPagination,
    // VsudPaginationItem
  },
  mounted() {
    this.getTickets()
  },
  computed: {
    
  },
  methods: {
    getTickets(){
      
      this.loading=true;
      
      var self = this
      this.form.company_id = this.$store.state.currentCompany_id;
      this.$axios.post(this.$store.state.baseUrl+'/api/tickets', this.form)
        .then(response => {
          if(response.data.rc == 1) {
            self.tickets = response.data.data
            if(this.max !== false)
                self.tickets = response.data.data.slice(0,this.max)
                self.loading = false

          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
          // self.loading = false

        })
    },
    filterTickets() {
      var tickets = this.tickets;
      // por estado
      tickets = this.tickets.filter(ticket => {
        if(this.selection == 'all')
         return true
        if(this.selection == 'pending')
          return (ticket.status == 'pending' || ticket.status == 'error' || ticket.status == 're-open')
        if(this.selection == 'closed')
          return ticket.status == 'done'
      })
      // alert(this.company_id)
      if(this.company_id)
        tickets =  tickets.filter(ticket => {
          return ticket.to_id == this.company_id
        });
      // busqueda de palabras
      if(this.form.search.length>0)
        tickets = tickets.filter(ticket => {
          var r = false;
          // cliente
          if(ticket.client)
            if(ticket.client.name.toLowerCase().includes(this.form.search.toLowerCase()) ||  (ticket.client.surnames && ticket.client.surnames.toLowerCase().includes(this.form.search.toLowerCase())))
              r = true;
          // empresa
          if(ticket.company  && ticket.company.name.toLowerCase().includes(this.form.search.toLowerCase()))
            r = true
          // titulo o descripcion
          if(ticket.title.toLowerCase().includes(this.form.search.toLowerCase()) || ticket.description.toLowerCase().includes(this.form.search.toLowerCase()))
            r = true
          return r
      })
      // por cliente
     
      
      return tickets;
    },    
    addTimming(ticketIn,index,event) {
      this.loading = true
      
      
      var self = this
      this.$axios.post(this.$store.state.baseUrl+'/api/tickets/'+ticketIn.id+'/timing', {
        minuts: event.target.value
      })
        .then(response => {
          if(response.data.rc == 1) {
            self.tickets[index] = response.data.data
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(
          this.loading = false
        )
    },
    deleteTicket(ticketIn) {
      if(!confirm("¿Estás seguro que deseas eliminar el tícket? \n "+ticketIn.title ))
        return ;
      this.loading = true
      
      
      var self = this
      this.$axios.post(this.$store.state.baseUrl+'/api/tickets/'+ticketIn.id+'/delete', null)
        .then(response => {
          if(response.data.rc == 1) {
            this.getTickets();
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
          this.loading = false
        })
    },
    humanDate(when) {
      return humanizeDuration(new Date(when),{
        language:"es"
      });
    }












  }
};
</script>
