
<template>
  <div class="fromSystem">
    <hr>
      <div v-if="message.ticket_id">
        {{ message.user.name }} ha creado un Asunto, 
        <router-link
        :to="{
          name: 'Ticket',
          params: {
            id: message.ticket_id
          }
        }"
        >haz click </router-link> para ir al Asunto
      </div>
    <hr>
  </div>
</template>
  
  <script>

  export default {
    name: "fromSystem",
    data() {
      return {

      };
    },
    props: ['message'],
    
  
  };
  </script>
  
  

    
  