<template>
    <div>
      <div v-if="showFilters" class=" pb-0">
        <div class="container-fluid">
          <div class="row d-flex justify-content-between">
            <div class="col-md-6 d-flex">
              <!-- <div class="filter m-auto w-100">
                <div class="input-group m-auto">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Buscar"
                    v-model="form.search"
                    @change="getData()"
                  />
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive p-0 col-12 mt-4">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 "
              >
                Leído - Título
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 "
              >
                Fecha
              </th>
              
              <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
              >
                Subtítulo
              </th>
              <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
              >
                Emisor
              </th>
              <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
              >
                Empresa
              </th>

              <!-- <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Abrir
              </th> -->
            </tr>
          </thead>

          <Cargador v-if="loading" />

          <tbody v-else >
            <h5 v-if="this.$store.state.general.notifications.length===0" class="m-4">No hay Notificaciones</h5>
            <tr v-for="notification in this.$store.state.general.notifications"  :key="notification.id">
              <td >
                <div class="d-flex px-2 py-1">
                  <div class="form-check">
                    <input v-model="notification.read" @change="checkNotification(notification)" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                  </div>
                  
                  <span v-if="notification.read">
                    <i class="fa fa-circle me-1 m-1 read"></i>
                  </span>
                  <span v-else ><i class="fa fa-circle me-1 m-1 pending"></i></span>
                  <span v-if="form.form!==null">
                    <router-link
                    v-if="notification.url !== null"
                    :to="{
                      path: '/'+notification.url
                    }">
                      <strong>{{notification.title}}</strong>
                    </router-link>  
                  </span>
                </div>
              </td>
              <td>
                {{this.$store.state.dateFormat(notification.created_at)}}
              </td>
              
              <td class=" d-none d-md-table-cell">
                <div class="d-flex px-2 py-1">
                    <span v-if="form.form!==null">{{notification.body}}</span>
                </div>
              </td>

              <td>
                <router-link
                v-if="notification.from !== null"
                class="AvatarAsesor"
                :to="{
                  path: '/user/'+notification.from.id
                }"
                >
                  <span  class="notification">{{notification.from.name}}</span>
              </router-link>
              </td>
              <td>
                <router-link
                v-if="notification.company"
                :to="{
                  path: '/company/'+notification.company.id
                }"
                >
                  <span  class="notification">{{notification.company.name}}</span>
              </router-link>
              </td>


              <!-- <td>
                <div class="d-flex px-2 py-1">
                  <router-link
                    v-if="notification.url !== null"
                    :to="{
                      path: '/'+notification.url
                    }">
                  <vsud-badge class="m-1" color="success" variant="gradient" size="m">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
                    </svg>
                  </vsud-badge>
                </router-link>

                </div>
              </td> -->


            </tr>




          </tbody>
        </table>

      </div>
    </div>

</template>

<script>
// import VsudBadge from "@/components/VsudBadge.vue";
import Cargador from "@/components/Cargador.vue";


// import VsudPagination from "@/components/VsudPagination.vue"
// import VsudPaginationItem from "@/components/VsudPaginationItem.vue"

export default {
  name: "forms-table",
  data() {
    return {
      loading:true,
      error: true,
      selection: 0,
      form: {
        search: ""
      }

    };
  },
  props: {
    showFilters:{
      type: Boolean,
      default:false
    }

  },
  components: {
    // VsudBadge,
    Cargador,
    // VsudPagination,
    // VsudPaginationItem
  },
  mounted() {

    this.filtros = this.filters
    this.getData()

  },
  methods: {
    checkNotification(notification) {
      // marcamos la notificación como leída 
      // notification.read = notification.read? false : true;
      // y lo enviamos a servidor
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/auth/notifications/'+notification.id, {
        read: notification.read
      })
      
      
       
      
    },
    getData(){

      //
      
      this.loading=true;
      
      this.$axios.post(this.$store.state.baseUrl+'/api/auth/notifications', this.form)
        .then(response => {
          if(response.data.rc == 1) {
            this.this.$store.state.general.notifications = response.data.data

          } 
        })
        .catch(() => {
            this.error = true
        })
        .finally(
          this.loading = false
        )
    },

  }
};
</script>
