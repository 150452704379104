<template>
  <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
            <div  class="nav-wrapper position-relative end-0">
              <ul
              v-if="this.$store.state.auth.id !== this.user.id"
              class="p-1 bg-transparent nav d-flex"
                role="tablist"
              >
              <!-- si es asesor y no es él mismo podrá abrir asunto -->
                <li v-if="this.$store.state.auth.rol == 'asesor' && this.user.rol == 'client' && this.user.companies.length == 1" class="nav-item m-1">
                  <router-link
                  :to="{
                    name:'Abrir Asunto',
                    query: {
                      to_id: this.user.companies[0].id
                    },
                    props: true
                  }"
                  >
                    <button type="button" class="btn btn-secondary" name="button">Abrir Asunto</button>
                  </router-link>

                               
                </li>
                <li v-if="user.companies >1"  @click="SolChat()" class="nav-item m-1">
                  <button type="button" class="btn btn-secondary" name="button">Chat</button>
                 </li>   
             

                <li  v-if="this.user.companies.length == 1 && this.$store.state.auth.rol == 'asesor'" class="nav-item m-1">
                  <router-link
                  :to="{
                       name: 'Solicitar Reunión',
                        query: {
                          to_id: this.user.companies[0].id
                         },
                         props: true
                       }"
                  >
                    <button type="button" class="btn btn-secondary" name="button">Solicitar Reunión</button>
                  </router-link>
                </li>

                

              </ul>
              
              <button @click="logout()" v-if="this.$store.state.auth.id == this.user.id" type="button" class="btn btn-link btn-logout" name="button">Logout</button>

            </div>
            
          </div>
</template>

<script>

import cookie from 'cookiejs';

export default {
  name: "ButtonsProfile",
  props: ['user'],
  methods: {
    SolChat(){
      var self = this;
      
      
      let formData = new FormData();
      // si el usuario consultado es asesor con y el actual cliente sola empresa es fácil, en caso de dos se habrá de seleccionar 
      if(this.$store.state.auth.rol == 'client') {
        if(this.$store.state.currentCompany_id)
          formData.append('company_id',  this.$store.state.currentCompany_id);
        

      } else {
        // si la persona que solicitamos es empresa y solo tiene una
        if(this.user.rol == 'client' && this.user.companies.length == 1)
          formData.append('company_id',  this.user.companies[0].id);
      }
      if(this.user.rol == 'asesor') 
          formData.append('asesor_id',  this.user.id);
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/chat/solicitar', formData)
        .then(response => {
          if(response.data.rc == 1) {
            // window.location.href = '/chats/'+response.data.data.id
            this.$router.push({path: '/chats/'+response.data.data.id })

          }
        })
        .catch(function () {
            self.error = true
        })
        .catch(function () {
            self.loading = false
        })
    },
    logout() {
      this.loading = true;

      var self = this;
      
      
      this.$axios.post(this.$store.state.baseUrl+'/api/logout', {
        device_token: self.$store.state.device_token
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.$store.state.auth = false;
            this.$store.state.token = false;
            // add token to cookie
            cookie.set("authToken",false,{expires: 365});
            // push the router to home
            // this.$router.push({ name: "/" }); Así no hace un refresh y no carga el usuario
            window.location.href = '/login';

          }
        })
        .catch(function () {
            self.error = true
        })
        .catch(function () {
            self.loading = false
        })

    }
  }
};
</script>
